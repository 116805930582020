import Link from "next/link";
import {
  ForwardedRef,
  forwardRef,
  HTMLProps,
  ReactElement,
  ReactHTML,
  ReactNode,
} from "react";
import { Tag as TagType } from "utils/api/models";
import { composeClassName } from "utils/misc";

type TagProps = {
  $as?: keyof ReactHTML;
  className?: string;
  color?: string;
  background?: string;
  border?: string;
  outlined?: boolean;
  children?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardedRef?: ForwardedRef<any>;
};

// TODO: Allow morphing
function Tag({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  $as = "span",
  className = "",
  color,
  background,
  border,
  outlined,
  children,
  forwardedRef,
  ...restProps
}: TagProps & Omit<HTMLProps<typeof $as>, keyof TagProps>): ReactElement {
  return (
    <$as
      className={composeClassName(
        "px-2 py-1 rounded font-bold font-mono text-xs uppercase",
        color,
        background,
        border,
        outlined && "border-2",
        className
      )}
      ref={forwardedRef}
      {...restProps}
    >
      {children}
    </$as>
  );
}

export default Tag;

// eslint-disable-next-line react/display-name
export const ForwardedTag = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      $as = "span",
      ...restProps
    }: TagProps & Omit<HTMLProps<typeof $as>, keyof TagProps>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: ForwardedRef<any>
  ) => <Tag forwardedRef={ref} $as={$as} {...restProps} />
);

export function LinkedTag({ tag }: { tag: TagType }): ReactElement {
  const url =
    tag.taggableType === "Article"
      ? `/${tag.article?.category.slug}/${tag.article?.slug}`
      : tag.taggableType == "Game"
      ? `/gry/${tag.game?.slug}`
      : `/wyszukiwarka?tag=${tag.id}`;

  return (
    <Link href={url} passHref>
      <ForwardedTag
        $as="a"
        color="text-gray-500"
        border="border-gray-500"
        outlined
        className="hover:bg-gray-500 hover:text-white"
      >
        {tag.name}
      </ForwardedTag>
    </Link>
  );
}
