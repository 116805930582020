import Image from "components/Image";
import Link from "next/link";
import type { ReactElement } from "react";
import { useLatestMagazineQuery } from "utils/api/hooks";
import { NAV } from "utils/constants";

import Logo from "../Logo";
import FooterNavigation from "./footer-navigation";

export default function Footer(): ReactElement {
  const latestMagazineQuery = useLatestMagazineQuery();

  return (
    <footer className="mt-12 z-10 relative bg-white dark:bg-black-200">
      <div className="flex items-center justify-center text-3xl font-bold text-center text-white uppercase lg:text-5xl p-11 font-display bg-gradient-to-br from-black to-gray-600 dark:from-orange-600 dark:to-orange-200">
        <Logo height="40px" width="100%" />
      </div>
      <div className="grid items-start grid-cols-12 px-3 py-8 mx-auto mt-6 lg:space-x-5 lg:mt-16 max-w-screen-2xl 2xl:px-0">
        <div className="col-span-12 lg:col-span-4">
          <Logo className="flex-shrink-0 order-1 fill-red dark:fill-white" />

          <div className="flex flex-row justify-between mt-8">
            <p className="w-1/2 text-sm text-gray-500">
              Oficjalny serwis największego magazynu o grach w Polsce, który od
              1996 roku dostarcza najświeższe i najlepsze informacje na temat
              gier komputerowych i konsolowych.
            </p>

            {latestMagazineQuery.data?.issueCoverUrl && (
              <Link
                href={`/czasopismo/${latestMagazineQuery.data.id}`}
                passHref
              >
                <a
                  className="relative ml-10 overflow-hidden rounded-lg lg:mr-5 w-max leading-0"
                  title={latestMagazineQuery.data.issueNumber}
                >
                  <Image
                    width={180}
                    height={240}
                    src={`${latestMagazineQuery.data.issueCoverUrl}?preset=medium`}
                    blurDataURL={`${latestMagazineQuery.data.issueCoverUrl}?preset=thumbnail`}
                    placeholder="blur"
                    alt={`Okładka dla ${latestMagazineQuery.data.issueNumber}`}
                  />
                </a>
              </Link>
            )}
          </div>
        </div>

        <div className="flex flex-col h-full col-span-12 mt-5 lg:col-span-8">
          <div className="flex flex-col items-start justify-between lg:flex-row">
            <FooterNavigation title="Menu" items={NAV.MAIN} />
            <FooterNavigation title="Info" items={NAV.INFO} />
            <FooterNavigation title="Info prawne" items={NAV.LEGAL} />
            <FooterNavigation
              title="Social"
              items={NAV.SOCIAL}
              openLinksInNewTab
            />
          </div>
          <div className="hidden mt-auto mb-8 font-mono text-xs text-gray-500 uppercase lg:block">
            Copyright by © Gaming Tech Esports Media SA. Wszystkie prawa
            zastrzeżone.
          </div>
        </div>

        {/* <div className="flex flex-row items-start col-span-12 p-4 mt-4 bg-gray-100 dark:bg-black-300 rounded-xl">
          <div className="mt-auto font-mono text-xs text-gray-500 uppercase">
            Polecamy:
          </div>

          <div className="w-24 h-6 ml-4 bg-white rounded-full"></div>
        </div> */}

        <div className="block col-span-12 mt-4 font-mono text-xs text-gray-500 uppercase lg:hidden">
          Copyright by © Gaming Tech Esports Media SA. Wszystkie prawa
          zastrzeżone.
        </div>
      </div>
    </footer>
  );
}
