import { CrossCircle } from "components/Icons";
import Image from "components/Image";
import { differenceInCalendarDays } from "date-fns";
import Link from "next/link";
import type {
  DetailedHTMLProps,
  HtmlHTMLAttributes,
  MouseEvent,
  MouseEventHandler,
  ReactElement,
} from "react";
import { useEffect, useRef, useState } from "react";
import { useLatestMagazineQuery } from "utils/api/hooks";
import { composeClassName } from "utils/misc";

export enum MagazineWidgetVariant {
  Vertical = "vertical",
  Horizontal = "horizontal",
}

type MagazineWidgetProps = {
  variant?: MagazineWidgetVariant;
  closeable?: boolean;
} & DetailedHTMLProps<HtmlHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export default function MagazineWidget({
  variant = MagazineWidgetVariant.Vertical,
  className,
  onClick,
  closeable = true,
}: MagazineWidgetProps): ReactElement | null {
  const latestMagazineQuery = useLatestMagazineQuery();

  const magazineIdFromLocalStorage =
    typeof window !== "undefined" &&
    window.localStorage &&
    localStorage.getItem("closedLatestMagazineWidget");

  const closeIconRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [isVisible, setIsVisible] = useState(closeable ? false : true);

  useEffect(() => {
    if (closeable) {
      if (magazineIdFromLocalStorage == latestMagazineQuery?.data?.id) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }
  }, [latestMagazineQuery?.data?.id]);

  const handleWidgetClick: MouseEventHandler = (e) => {
    if (closeIconRef.current?.contains(e.target as Element)) {
      e.preventDefault();
      setIsVisible(false);
      if (latestMagazineQuery?.data?.id) {
        localStorage.setItem(
          "closedLatestMagazineWidget",
          latestMagazineQuery.data.id
        );
      }
    } else {
      onClick && onClick(e as MouseEvent<HTMLAnchorElement>);
    }
  };

  if (!latestMagazineQuery?.data?.nextIssueOn || !isVisible) return null;

  return (
    <Link href={`/czasopismo/${latestMagazineQuery.data.id}`} passHref>
      <a
        className={composeClassName(
          "flex w-full px-4 py-8 rounded-lg bg-gradient-to-br from-blue-600 to-pink-600 relative",
          variant === MagazineWidgetVariant.Vertical && "flex-col",
          className
        )}
        onClick={handleWidgetClick}
      >
        {closeable && (
          <div ref={closeIconRef}>
            <CrossCircle className="absolute text-white top-2 right-2" />
          </div>
        )}

        <div>
          <div className="text-lg font-medium text-white uppercase font-display">
            {variant === MagazineWidgetVariant.Vertical && "Czasopismo "}
            <span className="text-orange">
              {latestMagazineQuery.data.issueNumber}
            </span>
          </div>

          {variant === MagazineWidgetVariant.Horizontal && (
            <div
              className={composeClassName(
                "flex flex-wrap justify-between font-medium text-white uppercase font-display",
                "text-2xl"
              )}
            >
              {differenceInCalendarDays(
                new Date(latestMagazineQuery.data.nextIssueOn),
                new Date()
              ) < 0 ? (
                <div
                  className={composeClassName("flex-grow leading-5", "w-full")}
                >
                  Już w sprzedaży!
                </div>
              ) : (
                <>
                  <div
                    className={composeClassName(
                      "flex-grow leading-5",
                      "w-full"
                    )}
                  >
                    Następne
                    <br /> wydanie za:
                  </div>
                  <div
                    className={composeClassName(
                      "flex flex-col items-center justify-center p-3 bg-white rounded-md",
                      "w-16 mt-4"
                    )}
                  >
                    <div className="text-4xl font-bold font-display text-red dark:text-orange">
                      {differenceInCalendarDays(
                        new Date(latestMagazineQuery.data.nextIssueOn),
                        new Date()
                      )}
                    </div>

                    <div className="font-mono text-xs font-bold leading-none uppercase text-gray">
                      dni
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        {latestMagazineQuery.data.issueCoverUrl && (
          <div
            className={composeClassName(
              "relative",
              variant === MagazineWidgetVariant.Vertical
                ? "w-full mt-5 h-64"
                : "w-32 ml-auto flex-shrink-0 h-40"
            )}
          >
            <Image
              src={`${latestMagazineQuery.data.issueCoverUrl}?preset=medium`}
              blurDataURL={`${latestMagazineQuery.data.issueCoverUrl}?preset=thumbnail`}
              placeholder="blur"
              layout="fill"
              objectFit="cover"
              className="rounded-md"
            />
          </div>
        )}

        {variant === MagazineWidgetVariant.Vertical && (
          <div
            className={composeClassName(
              "flex flex-wrap justify-between font-medium text-white uppercase font-display",
              "mt-5 text-lg"
            )}
          >
            {differenceInCalendarDays(
              new Date(latestMagazineQuery.data.nextIssueOn),
              new Date()
            ) < 0 ? (
              <div className={composeClassName("flex-grow leading-5")}>
                Już w sprzedaży!
              </div>
            ) : (
              <>
                <div className={composeClassName("flex-grow leading-5")}>
                  Następne
                  <br /> wydanie za:
                </div>
                <div
                  className={composeClassName(
                    "flex flex-col items-center justify-center p-3 bg-white rounded-md",
                    "flex-grow"
                  )}
                >
                  <div className="text-4xl font-bold font-display text-red dark:text-orange">
                    {differenceInCalendarDays(
                      new Date(latestMagazineQuery.data.nextIssueOn),
                      new Date()
                    )}
                  </div>

                  <div className="font-mono text-xs font-bold leading-none uppercase text-gray">
                    dni
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </a>
    </Link>
  );
}
