import React, { useContext, useState } from "react";

type INotificationModalContext = {
  isLogInNotificationOpen: boolean;
  openLogInNotification: () => void;
  closeLogInNotification: () => void;
  isNotificationPopupOpen: boolean;
  openNotificationPopup: () => void;
  closeNotificationPopup: () => void;
};

const NotificationModalContext = React.createContext(
  {} as INotificationModalContext
);

export function NotificationModalProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [isLogInNotificationOpen, setIsEditProfileFormOpen] = useState(false);
  const [isNotificationPopupOpen, setIsNotificationPopupOpen] = useState(false);

  const openLogInNotification = () => setIsEditProfileFormOpen(true);
  const closeLogInNotification = () => setIsEditProfileFormOpen(false);

  const openNotificationPopup = () => setIsNotificationPopupOpen(true);
  const closeNotificationPopup = () => setIsNotificationPopupOpen(false);

  return (
    <NotificationModalContext.Provider
      value={{
        isLogInNotificationOpen,
        openLogInNotification,
        closeLogInNotification,
        isNotificationPopupOpen,
        openNotificationPopup,
        closeNotificationPopup,
      }}
    >
      {children}
    </NotificationModalContext.Provider>
  );
}

export const useNotificationModalContext = (): INotificationModalContext =>
  useContext(NotificationModalContext);
