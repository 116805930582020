import { useRouter } from "next/router";
import React, { createContext, useContext, useEffect, useState } from "react";
import { apiClient } from "utils/api";
import { useBasicAccountLazyQuery } from "utils/api/hooks";
import { User } from "utils/api/models";
import { AUTH_TOKEN } from "utils/constants";

type AuthContextProps = {
  user: User | null;
  setUser: (user: User) => void;
  logout: () => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (authenticated: boolean) => void;
  refetch: () => void;
  isFetchingAuthenticatedUser: boolean;
};

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

export function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isFetchingAuthenticatedUser, setIsFetchingAuthenticatedUser] =
    useState(true);

  const router = useRouter();

  const accessToken =
    typeof window !== "undefined" &&
    localStorage?.getItem(AUTH_TOKEN) &&
    (localStorage.getItem(AUTH_TOKEN) as string);

  const [fetchUser, { isError, data, error, refetch }] =
    useBasicAccountLazyQuery();

  useEffect(() => {
    if (accessToken) {
      apiClient.defaults.headers.common["user-authorization"] = accessToken;
      fetchUser();
    }
  }, [accessToken]);

  useEffect(() => {
    if (data) {
      setUser(data);
      setIsAuthenticated(true);
      setIsFetchingAuthenticatedUser(false);
    }

    if (isError) {
      localStorage.removeItem(AUTH_TOKEN);
      setIsAuthenticated(false);
      setIsFetchingAuthenticatedUser(false);
    }
  }, [data, error, isError]);

  useEffect(() => {
    setIsFetchingAuthenticatedUser(true);
  }, []);

  function logout() {
    localStorage.removeItem(AUTH_TOKEN);

    setUser(null);
    setIsAuthenticated(false);

    router.push("/");
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        logout,
        isAuthenticated,
        setIsAuthenticated,
        refetch,
        isFetchingAuthenticatedUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = (): AuthContextProps => useContext(AuthContext);
