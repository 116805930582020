import { ContentPage, Editor, Game, Label, Tag } from ".";

export type Video = {
  id: number;
  title: string;
  slug: string;
  lead: string;
  type: VideoType;
  duration: string;
  source: string;
  thumbnailUrl: string;
  commentCount?: number;

  isFeaturedOnHomePage: boolean;
  isBlockedFromHomePage: boolean;
  publishedAt: string;

  labels: Label[];
  tags: Tag[];

  body: ContentPage[];
  game: Game;

  editor?: Editor;

  readTime: number;
  trackingCode?: string;
};

export enum VideoType {
  YouTube = "YouTube",
  Facebook = "Facebook",
  Twitch = "Twitch",
  Iframe = "Iframe",
}

export type IsVideoSaved = {
  isSaved: boolean;
};
