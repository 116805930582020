import { BlankAvatar, Comment } from "components/Icons";
import Image from "components/Image";
import Tag from "components/Tag";
import { format, formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import Link from "next/link";
import { Fragment, MouseEventHandler, ReactElement, useRef } from "react";
import { Article } from "utils/api/models";
import { composeClassName } from "utils/misc";

export enum ArticleCardVariant {
  // Primary
  // * uppercase & large title
  Primary = "primary",

  // Primary
  // * uppercase & large title
  // * summary
  PrimaryWithSummary = "primary-with-summary",

  // Default
  Default = "default",

  // Default
  // * line clamped to 2 lines
  DefaultWithTwoLinesTitle = "default-with-two-lines-title",

  // SeparatedCover
  // * cover is separated
  SeparatedCover = "separated-cover",

  // Secondary
  // * no gradient overlay
  // * info in half-transparent box
  Secondary = "secondary",

  // Secondary
  // * no gradient overlay
  // * info in half-transparent box
  // * tags above title
  SecondaryWithTags = "secondary-with-tags",

  // Horizontal
  // * no elevation
  Horizontal = "horizontal",

  // Horizontal Primary
  // * big cover & small elevation
  HorizontalPrimary = "horizontal-primary",
}

export type ArticleCardProps = {
  variant?: ArticleCardVariant;
  article?: Article;
  url?: string;
  hideCategory?: boolean;
  onClick?: MouseEventHandler;
};

export default function ArticleCard({
  variant = ArticleCardVariant.Default,
  article,
  url,
  hideCategory,
  onClick,
}: ArticleCardProps): ReactElement {
  if (!article) {
    return (
      <div className="w-full h-full bg-gray-200 rounded-xl animate-pulse dark:bg-black-300" />
    );
  }

  const cardRef = useRef<HTMLAnchorElement>(null);

  function getTextStyles() {
    switch (variant) {
      case ArticleCardVariant.Primary:
      case ArticleCardVariant.PrimaryWithSummary:
        return "text-3xl lg:text-5xl font-medium text-white uppercase font-display line-clamp-5";

      case ArticleCardVariant.Secondary:
      case ArticleCardVariant.SecondaryWithTags:
        return "text-2xl font-medium text-white font-display line-clamp-5";

      case ArticleCardVariant.HorizontalPrimary:
        return "text-lg lg:text-5xl font-medium text-black dark:text-white lg:uppercase font-display line-clamp-5 group-hover:text-orange";

      case ArticleCardVariant.Horizontal:
        return "text-lg lg:text-3xl font-medium text-black dark:text-white font-display line-clamp-5 group-hover:text-orange";

      case ArticleCardVariant.SeparatedCover:
        return "text-2xl font-medium text-black dark:text-white font-display uppercase line-clamp-3";

      case ArticleCardVariant.DefaultWithTwoLinesTitle:
        return "text-2xl font-medium text-white font-display line-clamp-2";

      case ArticleCardVariant.Default:
      default:
        return "text-2xl font-medium text-white font-display line-clamp-3";
    }
  }

  return (
    <Link href={url || `/${article?.category?.slug}/${article?.slug}`} passHref>
      <a
        ref={cardRef}
        onClick={onClick}
        className={composeClassName(
          `relative flex group flex-grow w-full h-full overflow-hidden rounded-xl cursor-pointer transition-fix `,
          variant !== ArticleCardVariant.Secondary &&
            variant !== ArticleCardVariant.SecondaryWithTags &&
            variant !== ArticleCardVariant.HorizontalPrimary &&
            variant !== ArticleCardVariant.Horizontal &&
            variant !== ArticleCardVariant.SeparatedCover &&
            `after:absolute after:bg-gradient-to-t after:from-black after:inset-0 after:z-0`,
          variant === ArticleCardVariant.HorizontalPrimary &&
            "h-80 bg-gray-100 dark:bg-black-300 pr-4 content-start",
          variant === ArticleCardVariant.Horizontal &&
            "!h-44 pr-4 content-start",
          variant === ArticleCardVariant.SeparatedCover ? "py-4" : "p-4",
          variant !== ArticleCardVariant.HorizontalPrimary &&
            variant !== ArticleCardVariant.Horizontal &&
            "flex-col"
        )}
      >
        <div
          className={composeClassName(
            `absolute inset-0 z-0 rounded-xl overflow-hidden`,
            variant === ArticleCardVariant.HorizontalPrimary && "w-40 lg:w-96",
            variant === ArticleCardVariant.Horizontal && "w-36 lg:w-48",
            variant === ArticleCardVariant.SeparatedCover && "h-52"
          )}
          aria-hidden
        >
          {article?.bannerUrl && article?.bannerUrl.includes("http") && (
            <div
              className={composeClassName(
                "absolute inset-0 transition-transform",
                variant !== ArticleCardVariant.HorizontalPrimary &&
                  variant !== ArticleCardVariant.Horizontal &&
                  "group-hover:scale-110"
              )}
            >
              <Image
                src={`${article?.bannerUrl}?preset=${
                  variant === ArticleCardVariant.Primary ||
                  variant === ArticleCardVariant.PrimaryWithSummary ||
                  variant === ArticleCardVariant.Secondary ||
                  variant === ArticleCardVariant.SecondaryWithTags
                    ? "large"
                    : "small"
                }`}
                blurDataURL={`${article?.bannerUrl}?preset=thumbnail`}
                layout="fill"
                placeholder="blur"
                objectFit="cover"
                alt={article?.title}
              />
            </div>
          )}
        </div>

        <div
          className={composeClassName(
            "z-10 flex items-start space-x-2 flex-shrink-0",
            variant === ArticleCardVariant.HorizontalPrimary &&
              "w-40 lg:w-96 h-80",
            variant === ArticleCardVariant.Horizontal && "w-36 lg:w-48 h-52",
            variant === ArticleCardVariant.SeparatedCover && "h-6 ml-4"
          )}
        >
          {article?.isPopular && (
            <Tag color="text-white" background="bg-red">
              Hit
            </Tag>
          )}

          {article?.isSponsored && (
            <Tag color="text-black" background="bg-yellow-100">
              Sponsorowany
            </Tag>
          )}

          {article?.labels &&
            article?.labels.map((label, index) => (
              <Tag
                key={`article-label-${index}`}
                color="text-white"
                style={{ backgroundColor: label.color }}
              >
                {label.name}
              </Tag>
            ))}
        </div>

        <div
          className={composeClassName(
            "z-10",
            variant !== ArticleCardVariant.HorizontalPrimary &&
              variant !== ArticleCardVariant.Horizontal &&
              variant !== ArticleCardVariant.SeparatedCover &&
              "mt-auto",
            (variant === ArticleCardVariant.Secondary ||
              variant === ArticleCardVariant.SecondaryWithTags) &&
              `p-4 rounded-lg bg-black bg-opacity-60`
          )}
        >
          {variant !== ArticleCardVariant.Secondary && (
            <div
              className={composeClassName(
                "flex mb-4",
                !hideCategory ? "space-x-3" : "-ml-2",
                variant === ArticleCardVariant.SeparatedCover && "mt-48"
              )}
            >
              {!hideCategory && (
                <Tag
                  color={
                    variant !== ArticleCardVariant.HorizontalPrimary &&
                    variant !== ArticleCardVariant.Horizontal &&
                    variant !== ArticleCardVariant.SeparatedCover
                      ? "text-white"
                      : "text-black dark:text-white"
                  }
                  border={
                    variant !== ArticleCardVariant.HorizontalPrimary &&
                    variant !== ArticleCardVariant.Horizontal &&
                    variant !== ArticleCardVariant.SeparatedCover
                      ? "border-white"
                      : "border-black dark:border-white"
                  }
                  outlined
                >
                  {article?.category?.name}
                </Tag>
              )}

              <Tag
                color={
                  variant !== ArticleCardVariant.HorizontalPrimary &&
                  variant !== ArticleCardVariant.Horizontal &&
                  variant !== ArticleCardVariant.SeparatedCover
                    ? "text-white"
                    : "text-black dark:text-white"
                }
                border="border-transparent"
                outlined
              >
                <Fragment>
                  {+new Date(article?.publishedAt) >= +new Date() - 86400000 &&
                    formatDistanceToNow(new Date(article?.publishedAt), {
                      locale: pl,
                      addSuffix: true,
                    })}
                  {+new Date(article?.publishedAt) < +new Date() - 86400000 &&
                    format(new Date(article?.publishedAt), "d.MM.yyyy", {
                      locale: pl,
                    })}
                </Fragment>
              </Tag>
            </div>
          )}

          <h2 className={`mb-4 ${getTextStyles()}`}>{article?.title}</h2>

          {variant === ArticleCardVariant.PrimaryWithSummary &&
            article?.summary && (
              <p className={`mb-4 text-white line-clamp-3`}>
                {article?.summary}
              </p>
            )}

          <div
            className={composeClassName(
              "flex space-x-7",
              variant === ArticleCardVariant.HorizontalPrimary ||
                (variant === ArticleCardVariant.Horizontal && "mt-auto")
            )}
          >
            <div className="flex items-center">
              <div
                className={composeClassName(
                  "relative flex items-center w-5 h-5 overflow-hidden rounded-full",
                  variant !== ArticleCardVariant.HorizontalPrimary &&
                    variant !== ArticleCardVariant.Horizontal &&
                    variant !== ArticleCardVariant.SeparatedCover
                    ? "text-white"
                    : "text-black dark:text-white"
                )}
              >
                {article?.editor?.avatarUrl ? (
                  <Image
                    layout="fill"
                    objectFit="cover"
                    src={`${article?.editor?.avatarUrl}?preset=thumbnail`}
                    blurDataURL={`${article?.editor?.avatarUrl}?preset=thumbnail`}
                    alt={article?.editor?.nick}
                    placeholder="blur"
                  />
                ) : (
                  <BlankAvatar className="w-5 h-5" />
                )}
              </div>

              <span
                className={composeClassName(
                  "ml-3 font-mono text-xs font-bold uppercase",
                  variant !== ArticleCardVariant.HorizontalPrimary &&
                    variant !== ArticleCardVariant.Horizontal &&
                    variant !== ArticleCardVariant.SeparatedCover
                    ? "text-white"
                    : "text-black dark:text-white"
                )}
              >
                {article?.editor?.nick}
              </span>
            </div>

            {article?.commentCount > 0 && (
              <div
                className={composeClassName(
                  "flex items-center",
                  variant !== ArticleCardVariant.HorizontalPrimary &&
                    variant !== ArticleCardVariant.Horizontal &&
                    variant !== ArticleCardVariant.SeparatedCover
                    ? "text-white"
                    : "text-black dark:text-white"
                )}
              >
                <Comment />

                <span className={`ml-3 font-bold font-mono text-xs uppercase`}>
                  {article?.commentCount}
                </span>
              </div>
            )}
          </div>
        </div>
      </a>
    </Link>
  );
}
