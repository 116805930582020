import CategoryWidget, {
  CategoryWidgetVariant,
} from "components/CategoryWidget";
import Image from "components/Image";
import Input, { InputVariant } from "components/Input";
import MagazineWidget, {
  MagazineWidgetVariant,
} from "components/MagazineWidget";
import { useAuth } from "contexts/auth-context";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import { Fragment, MouseEvent, ReactElement, useEffect, useState } from "react";
import { NAV, NavItem } from "utils/constants";
import { composeClassName } from "utils/misc";

import {
  BlankAvatar,
  ChevronDown,
  Close,
  Hamburger,
  Magnifier,
  Plus,
} from "../Icons";
import Logo from "../Logo";
import ThemeSwitch from "../ThemeSwitch";

export enum HeaderKind {
  Standard = "standard",
  Minimal = "minimal",
}

type HeaderProps = {
  kind?: HeaderKind;
};

export default function Header({
  kind = HeaderKind.Standard,
}: HeaderProps): ReactElement {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [submenu, setSubmenu] = useState<NavItem | null>(null);

  const [searchPhrase, setSearchPhrase] = useState("");

  const { isAuthenticated, user } = useAuth();

  const router = useRouter();

  const getSearchCategoryPath = () => {
    if (router.asPath.includes("wyszukiwarka")) {
      if (router.asPath.split("/")[2]) {
        return `/${router.asPath.split("/")[2].split("?")[0]}`;
      }
    }
    return "";
  };

  useEffect(() => {
    if (document) {
      if (isNavigationOpen || isSearchOpen) {
        document.body.classList.add("!overflow-y-hidden");
      } else {
        document.body.classList.remove("!overflow-y-hidden");
      }
    }
  }, [isNavigationOpen, isSearchOpen]);

  useEffect(() => {
    if (document) {
      if (submenu) {
        document.body.classList.add("!overflow-y-hidden");
        document.body.classList.add("md:!overflow-y-auto");
      } else {
        document.body.classList.remove("!overflow-y-hidden");
        document.body.classList.remove("md:!overflow-y-auto");
      }
    }
  }, [submenu]);

  useEffect(() => {
    function closeSubmenu() {
      setSubmenu(null);
    }

    if (document && document.getElementById("maincontainer")) {
      if (submenu) {
        document
          .getElementById("maincontainer")
          ?.addEventListener("click", closeSubmenu);
      } else {
        document
          .getElementById("maincontainer")
          ?.removeEventListener("click", closeSubmenu);
      }
    }
  }, [submenu]);

  useEffect(() => {
    let previousScrollValue: number;

    function handleScroll() {
      const currentScroll = window.scrollY;

      if (typeof previousScrollValue !== "number") {
        previousScrollValue = currentScroll;
        return;
      }

      const direction = currentScroll > previousScrollValue ? "down" : "up";

      if (isHeaderVisible && direction === "down" && currentScroll > 400) {
        setIsHeaderVisible(false);
      } else if (!isHeaderVisible && direction === "up") {
        setIsHeaderVisible(true);
      }

      previousScrollValue = currentScroll;
    }

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isHeaderVisible]);

  return (
    <Fragment>
      <header
        className={composeClassName(
          "top-0 z-40 w-screen max-w-full px-3 py-8 transition-transform",
          submenu || isHeaderVisible ? "translate-y-0" : "-translate-y-full",
          kind === HeaderKind.Minimal
            ? "absolute"
            : composeClassName(
                isNavigationOpen || isSearchOpen ? "fixed" : "sticky",
                "bg-white dark:bg-black-200 2xl:px-0"
              ),
          !submenu && "dark:bg-opacity-80"
        )}
      >
        <div
          className={composeClassName(
            "relative flex flex-wrap items-center mx-auto",
            kind !== HeaderKind.Minimal && "max-w-screen-2xl"
          )}
        >
          <Link href="/" passHref>
            <a
              className="order-1 w-32 flex-shrink-1 sm:w-[170px]"
              onClick={() => {
                setSubmenu(null);
                setIsHeaderVisible(true);
                setIsNavigationOpen(false);
                setIsSearchOpen(false);
              }}
              title="CD-Action"
            >
              <Logo
                betaMark
                className={composeClassName(
                  "cursor-pointer w-full",
                  kind === HeaderKind.Minimal && !isNavigationOpen
                    ? "fill-white"
                    : "fill-red dark:fill-white"
                )}
              />
            </a>
          </Link>

          {!isNavigationOpen && !isSearchOpen && kind !== HeaderKind.Minimal && (
            <nav className="order-3 w-screen h-4 mx-auto -mr-3 overflow-hidden lg:mr-auto max-w-screen-off-left xl:w-auto mt-7 xl:flex-grow-0 xl:mt-0 xl:order-2">
              <div className="box-content flex h-full max-w-full pb-2 space-x-4 overflow-x-auto overflow-y-hidden">
                {NAV.MAIN.map((item, index) => (
                  <Link key={`top-nav-item-${index}`} passHref href={item.url}>
                    <a
                      onClick={(event: MouseEvent) => {
                        if (item.children) {
                          event.preventDefault();
                          setSubmenu(submenu === item ? null : item);
                        } else {
                          setSubmenu(null);
                          setIsHeaderVisible(true);
                        }
                      }}
                      className={composeClassName(
                        `whitespace-nowrap font-mono text-xs font-bold leading-3 uppercase cursor-pointer hover:text-red dark:hover:text-orange flex items-center`,
                        (`/${router.asPath.split("/")[1]}` === item.url &&
                          !submenu) ||
                          submenu === item
                          ? "text-red dark:text-orange"
                          : "text-gray-500"
                      )}
                      target={item.openLinksInNewTab ? "_blank" : "_self"}
                      rel={item.openLinksInNewTab ? "norefferer noopener" : ""}
                    >
                      {item.label}

                      {item.children && (
                        <ChevronDown
                          className={composeClassName(
                            "w-2 h-1.5 ml-1.5",
                            submenu === item && "rotate-180"
                          )}
                        />
                      )}
                    </a>
                  </Link>
                ))}
              </div>
            </nav>
          )}

          <div
            className={composeClassName(
              "flex items-center order-2 ml-auto space-x-5 xl:order-3",
              !isNavigationOpen &&
                !isSearchOpen &&
                kind !== HeaderKind.Minimal &&
                "xl:ml-0 -mr-px"
            )}
          >
            <ThemeSwitch />

            {isAuthenticated ? (
              <Link href="/dashboard" passHref>
                <a
                  onClick={() => {
                    setSubmenu(null);
                    setIsHeaderVisible(true);
                    setIsNavigationOpen(false);
                    setIsNavigationOpen(false);
                  }}
                  className="flex items-center w-5 h-5 overflow-hidden border rounded-full border-green dark:border-orange"
                  title="Dashboard"
                >
                  {user?.avatarUrl ? (
                    <Image
                      height="18px"
                      width="18px"
                      placeholder="blur"
                      src={`${user.avatarUrl}?preset=medium`}
                      blurDataURL={`${user.avatarUrl}?preset=thumbnail`}
                      objectFit="cover"
                    />
                  ) : (
                    <BlankAvatar />
                  )}
                </a>
              </Link>
            ) : (
              <Link href="/logowanie" passHref>
                <a
                  onClick={() => {
                    setSubmenu(null);
                    setIsHeaderVisible(true);
                    setIsNavigationOpen(false);
                    setIsNavigationOpen(false);
                  }}
                  className="text-gray-500 dark:text-white"
                  title="Zaloguj się"
                >
                  <BlankAvatar />
                </a>
              </Link>
            )}

            <button
              onClick={() => {
                setIsSearchOpen((isSearchOpen) => !isSearchOpen);
                setIsNavigationOpen(false);
                setSubmenu(null);
              }}
              className="w-5"
              aria-label="Wyszukiwarka"
            >
              {isSearchOpen ? (
                <Close className="text-gray-500 dark:hover:text-orange hover:text-red dark:text-white" />
              ) : (
                <Magnifier className="text-gray-500 dark:text-white dark:hover:text-orange hover:text-red" />
              )}
            </button>

            <button
              onClick={() => {
                setIsNavigationOpen((isNavigationOpen) => !isNavigationOpen);
                setSubmenu(null);
                setIsSearchOpen(false);
              }}
              className="w-5"
              aria-label="Nawigacja"
            >
              {isNavigationOpen ? (
                <Close className="text-gray-500 dark:hover:text-orange hover:text-red dark:text-white" />
              ) : (
                <Hamburger className="dark:stroke-white dark:hover:stroke-orange hover:stroke-red" />
              )}
            </button>
          </div>
        </div>

        {submenu && !isNavigationOpen && (
          <div className="absolute left-0 right-0 z-30 px-3 max-h-off-header overflow-x-hidden overflow-y-scroll bg-gray-100 rounded-b-lg shadow-lg top-full lg:bottom-auto py-7 bg-opacity-97.5 2xl:px-0 dark:bg-black-200 dark:bg-opacity-95 lg:overflow-y-hidden">
            <div className="relative grid grid-cols-12 px-3 mx-auto lg:mt-0 lg:px-0 max-w-screen-2xl">
              <div className="col-span-12 pb-5">
                <div className="font-mono text-xs font-bold text-black uppercase dark:text-white">
                  {submenu.label}
                </div>
              </div>
            </div>

            <div className="relative grid grid-cols-12 px-3 mx-auto lg:space-x-5 lg:px-0 max-w-screen-2xl">
              <div className="col-span-12 lg:col-span-3 min-h-60">
                {submenu.children?.map((item, index) => (
                  <Link
                    key={`fs-nav-subitem-${index}`}
                    passHref
                    href={item.url}
                  >
                    <a
                      onClick={() => setSubmenu(null)}
                      className={composeClassName(
                        `py-4 text-2xl box-content font-medium relative text-left flex justify-between items-center hover:text-red dark:hover:text-orange text-gray-500 uppercase border-b font-display border-gray-500 border-opacity-20 dark:border-black-100`,
                        router.asPath.includes(item.url) &&
                          `text-transparent bg-clip-text bg-gradient-to-br from-red to-red-200 dark:from-orange-600 dark:via-orange dark:to-orange-200 after:w-8 after:h-px after:rounded after:absolute after:right-0 after:bottom-0 after:bg-red dark:after:bg-orange`
                      )}
                    >
                      <span className="h-5 leading-none">{item.label}</span>
                    </a>
                  </Link>
                ))}
              </div>

              <div className="h-full col-span-12 lg:col-span-9">
                {submenu.categoryId && (
                  <CategoryWidget
                    className="h-80 md:h-60"
                    categoryId={submenu.categoryId}
                    variant={CategoryWidgetVariant.Carousel}
                    limit={3}
                    onClick={() => setSubmenu(null)}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </header>

      {isNavigationOpen && (
        <div className="fixed inset-0 left-0 right-0 z-30 px-3 overflow-x-hidden overflow-y-scroll bg-gray-100 2xl:px-0 dark:bg-black-200 lg:overflow-y-auto">
          <div className="relative grid grid-cols-12 px-3 mx-auto lg:space-x-5 lg:px-0 py-7 max-w-screen-2xl mt-22">
            <div className="col-span-12 pb-5 sm:col-span-6 lg:col-span-3 2xl:col-span-2">
              <div className="font-mono text-xs font-bold text-black uppercase dark:text-white">
                Menu
              </div>

              <div className="flex flex-col w-full mt-2">
                {NAV.MAIN.map((item, index) => (
                  <Fragment key={`fs-nav-item-${index}`}>
                    <Link passHref href={item.url}>
                      <a
                        onClick={(event: MouseEvent) => {
                          if (item.children) {
                            event.preventDefault();
                            setSubmenu(submenu === item ? null : item);
                          } else {
                            setIsNavigationOpen(false);
                            setSubmenu(null);
                          }
                        }}
                        className={composeClassName(
                          `py-4 text-3xl box-content font-medium relative text-left flex justify-between items-center hover:text-red dark:hover:text-orange text-gray-500 uppercase border-b font-display border-white dark:border-black-100 last-of-type:border-b-0`,
                          ((router.asPath.includes(item.url) && !submenu) ||
                            submenu === item) &&
                            `text-transparent bg-clip-text bg-gradient-to-br from-red to-red-200 dark:from-orange-600 dark:via-orange dark:to-orange-200 after:w-8 after:h-px after:rounded after:absolute after:right-0 after:bottom-0 after:bg-red dark:after:bg-orange`
                        )}
                        target={item.openLinksInNewTab ? "_blank" : "_self"}
                        rel={
                          item.openLinksInNewTab ? "norefferer noopener" : ""
                        }
                      >
                        <span className="h-5 leading-none">{item.label}</span>

                        {item.children &&
                          (submenu === item ? (
                            <Close className="flex-shrink-0 w-4 h-4 text-red dark:text-orange" />
                          ) : (
                            <Plus className="flex-shrink-0 w-5 h-5 text-red dark:text-orange" />
                          ))}
                      </a>
                    </Link>

                    {item.children && submenu === item && (
                      <div className="relative flex flex-col w-screen py-5 pl-6 sm:hidden -left-6 -right-6 bg-navy-100 dark:bg-black-300 ">
                        {submenu.children?.map((item, index) => (
                          <Link
                            key={`fs-nav-subitem-${index}`}
                            passHref
                            href={item.url}
                          >
                            <a
                              onClick={() => {
                                setIsNavigationOpen(false);
                                setSubmenu(null);
                              }}
                              className={composeClassName(
                                `py-4 text-2xl box-content font-medium relative text-left flex justify-between items-center hover:text-red dark:hover:text-orange text-gray-500 uppercase border-b font-display border-gray-500 border-opacity-20 dark:border-black-100`,
                                router.asPath.includes(item.url) &&
                                  `text-transparent bg-clip-text bg-gradient-to-br from-red to-red-200 dark:from-orange-600 dark:via-orange dark:to-orange-200 after:w-8 after:h-px after:rounded after:absolute after:right-0 after:bottom-0 after:bg-red dark:after:bg-orange`
                              )}
                            >
                              <span className="h-5 leading-none">
                                {item.label}
                              </span>
                            </a>
                          </Link>
                        ))}

                        {submenu.categoryId && (
                          <CategoryWidget
                            variant={CategoryWidgetVariant.Carousel}
                            categoryId={submenu.categoryId}
                            className="mt-10 space-y-4 children:h-80"
                            onClick={() => {
                              setIsNavigationOpen(false);
                              setSubmenu(null);
                            }}
                          />
                        )}
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>

            <div
              className={composeClassName(
                "hidden sm:col-span-6 lg:col-span-3 sm:flex flex-col lg:mb-20",
                !!submenu &&
                  "bg-navy-100 dark:bg-black-300 px-5 py-6 rounded-lg"
              )}
            >
              {submenu?.children && (
                <Fragment>
                  <div className="flex flex-col w-full">
                    {submenu.children.map((item, index) => (
                      <Link
                        key={`fs-nav-subitem-${index}`}
                        passHref
                        href={item.url}
                      >
                        <a
                          onClick={() => {
                            setIsNavigationOpen(false);
                            setSubmenu(null);
                          }}
                          className={composeClassName(
                            `py-4 text-2xl box-content font-medium relative text-left flex justify-between items-center hover:text-red dark:hover:text-orange text-gray-500 uppercase border-b font-display border-gray-500 border-opacity-20 dark:border-black-100`,
                            router.asPath.includes(item.url) &&
                              `text-transparent bg-clip-text bg-gradient-to-br from-red to-red-200 dark:from-orange-600 dark:via-orange dark:to-orange-200 after:w-8 after:h-px after:rounded after:absolute after:right-0 after:bottom-0 after:bg-red dark:after:bg-orange`
                          )}
                        >
                          <span className="h-5 leading-none">{item.label}</span>
                        </a>
                      </Link>
                    ))}
                  </div>

                  {submenu.categoryId && (
                    <CategoryWidget
                      categoryId={submenu.categoryId}
                      className="mt-auto space-y-4 children:h-60"
                      onClick={() => {
                        setIsNavigationOpen(false);
                        setSubmenu(null);
                      }}
                    />
                  )}
                </Fragment>
              )}
            </div>

            <div className="col-span-12 lg:col-span-6 2xl:col-span-5">
              <div className="flex flex-wrap justify-between lg:space-x-5 lg:flex-wrap-0 children:flex-grow children:flex-shrink-0">
                <div className="mt-10 lg:mt-0">
                  <div className="font-mono text-xs font-bold text-black uppercase dark:text-white">
                    Info
                  </div>

                  <div className="flex flex-col w-full mt-4">
                    {NAV.INFO.map((item, index) => (
                      <Link
                        key={`fs-nav-subitem-${index}`}
                        passHref
                        href={item.url}
                      >
                        <a
                          onClick={() => {
                            setIsNavigationOpen(false);
                            setSubmenu(null);
                          }}
                          className={composeClassName(
                            `py-1 text-2xl box-content font-medium relative text-left text-gray-500 font-display hover:text-red dark:hover:text-orange `,
                            router.asPath.includes(item.url) &&
                              `text-transparent bg-clip-text bg-gradient-to-br from-red to-red-200 dark:from-orange-600 dark:via-orange dark:to-orange-200`
                          )}
                          target={item.openLinksInNewTab ? "_blank" : "_self"}
                          rel={
                            item.openLinksInNewTab ? "norefferer noopener" : ""
                          }
                        >
                          <span>{item.label}</span>
                        </a>
                      </Link>
                    ))}
                  </div>
                </div>

                <div className="w-full mt-10 lg:mt-0 lg:w-auto">
                  <div className="font-mono text-xs font-bold text-black uppercase dark:text-white">
                    Social
                  </div>

                  <div className="flex flex-col w-full mt-4">
                    {NAV.SOCIAL.map((item, index) => (
                      <Link
                        key={`fs-nav-subitem-${index}`}
                        passHref
                        href={item.url}
                      >
                        <a
                          onClick={() => {
                            setIsNavigationOpen(false);
                            setSubmenu(null);
                          }}
                          className={composeClassName(
                            `py-1 text-2xl box-content font-medium relative flex items-center text-left text-gray-500 font-display hover:text-red dark:hover:text-orange `,
                            router.asPath.includes(item.url) &&
                              `text-transparent bg-clip-text bg-gradient-to-br from-red to-red-200 dark:from-orange-600 dark:via-orange dark:to-orange-200`
                          )}
                          target="_blank"
                          rel="norefferer noopener"
                        >
                          <span className="flex items-start justify-center w-5 h-5 mr-3">
                            {item.icon}
                          </span>
                          <span>{item.label}</span>
                        </a>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              <hr className="hidden w-full h-px mt-8 mb-8 bg-gray-500 border-none outline-none lg:block bg-opacity-20 dark:bg-black-100" />

              <div className="flex justify-between mt-10 space-x-5 children:flex-grow children:flex-shrink-0 lg:mt-0">
                <div>
                  <div className="font-mono text-xs font-bold text-black uppercase dark:text-white">
                    Legal
                  </div>

                  <div className="flex flex-col w-full mt-4">
                    {NAV.LEGAL.map((item, index) => (
                      <Link
                        key={`fs-nav-subitem-${index}`}
                        passHref
                        href={item.url}
                      >
                        <a
                          onClick={() => {
                            setIsNavigationOpen(false);
                            setSubmenu(null);
                          }}
                          className={composeClassName(
                            `py-1 text-2xl box-content font-medium relative text-left text-gray-500 font-display hover:text-red dark:hover:text-orange `,
                            router.asPath.includes(item.url) &&
                              `text-transparent bg-clip-text bg-gradient-to-br from-red to-red-200 dark:from-orange-600 dark:via-orange dark:to-orange-200`
                          )}
                        >
                          <span>{item.label}</span>
                        </a>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              <div className="block mt-5 2xl:hidden">
                <MagazineWidget
                  variant={MagazineWidgetVariant.Horizontal}
                  onClick={() => {
                    setIsNavigationOpen(false);
                    setSubmenu(null);
                  }}
                  closeable={false}
                />
              </div>
            </div>

            <div className="hidden col-span-2 2xl:block">
              <MagazineWidget
                onClick={() => {
                  setIsNavigationOpen(false);
                  setSubmenu(null);
                }}
                closeable={false}
              />
            </div>
          </div>
        </div>
      )}

      {isSearchOpen && (
        <div className="fixed inset-0 left-0 right-0 z-30 flex flex-col px-3 overflow-x-hidden overflow-y-scroll bg-gray-100 2xl:px-0 dark:bg-black-200 lg:overflow-y-auto">
          <div className="relative grid flex-grow w-full grid-cols-12 px-3 mx-auto lg:space-x-5 lg:px-0 py-7 max-w-screen-2xl mt-22">
            <div className="hidden md:block md:col-span-2 lg:col-span-3" />

            <div className="flex flex-col col-span-12 md:col-span-8 lg:col-span-6">
              <div className="mt-auto mb-auto">
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    setIsSearchOpen(false);
                    router.push(
                      `/wyszukiwarka${getSearchCategoryPath()}?fraza=${searchPhrase}`
                    );
                    setSearchPhrase("");
                  }}
                >
                  <Input
                    variant={InputVariant.Large}
                    placeholder="Szukaj"
                    className="mt-auto"
                    autoFocus={isSearchOpen}
                    endEnhancer={
                      <Magnifier
                        className="w-12 h-12 cursor-pointer text-red dark:text-orange"
                        onClick={() => {
                          setIsSearchOpen(false);
                          router.push(
                            `/wyszukiwarka${getSearchCategoryPath()}?fraza=${searchPhrase}`
                          );
                          setSearchPhrase("");
                        }}
                      />
                    }
                    value={searchPhrase}
                    onChange={(event) => setSearchPhrase(event.target.value)}
                  />
                </form>
              </div>

              <div className="mb-12 font-mono text-sm text-center text-gray-500 uppercase">
                Wpisz wyszukiwaną frazę, lub przejdź do{" "}
                <Link href="/wyszukiwarka" passHref>
                  <a
                    className="text-black underline dark:text-white"
                    onClick={() => {
                      setIsSearchOpen(false);
                    }}
                  >
                    pełnej wyszukiwarki
                  </a>
                </Link>
                .
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
