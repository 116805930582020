import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import {
  ArticleCategoriesQueryParams,
  ArticlesQueryParams,
  CommentsQueryParams,
  createArticleComment,
  CreateCommentMutationParams,
  createGameComment,
  createVideoComment,
  editComment,
  EditCommentMutationParams,
  EditorsQueryParams,
  fetchAccount,
  fetchArticle,
  fetchArticleCategories,
  fetchArticleCategory,
  fetchArticleComments,
  fetchArticles,
  fetchBasicAccount,
  fetchEditor,
  fetchEditorArticles,
  fetchEditors,
  fetchGame,
  fetchGameComments,
  fetchGames,
  fetchIsArticleSaved,
  fetchIsEditorFollowed,
  fetchIsGameSaved,
  fetchIsMagazineSaved,
  fetchIsVideoSaved,
  fetchLatestMagazine,
  fetchMagazine,
  fetchMagazines,
  fetchSections,
  fetchStaticPage,
  fetchStaticPages,
  fetchTags,
  fetchUser,
  fetchUserGameMarks,
  fetchVideo,
  fetchVideoComments,
  fetchVideos,
  followEditor,
  followGame,
  GamesMutationParams,
  GamesQueryParams,
  login,
  LoginMutationParams,
  MagazinesQueryParams,
  markGame,
  rateGame,
  reportComment,
  ReportCommentMutationParams,
  saveArticle,
  SaveArticleMutationParams,
  saveGame,
  SaveGameMutationParams,
  saveMagazine,
  SaveMagazineMutationParams,
  saveVideo,
  SaveVideoMutationParams,
  search,
  SearchQueryParams,
  SearchResponse,
  setArticleAsRead,
  StaticPagesQueryParamas,
  unfollowEditor,
  unfollowGame,
  unmarkGame,
  unsaveArticle,
  unsaveGame,
  unsaveMagazine,
  unsaveVideo,
  unupvoteComment,
  UnupvoteCommentMutationParams,
  updateAccount,
  upvoteComment,
  UpvoteCommentMutationParams,
  VideosQueryParams,
} from "utils/api";
import {
  Article,
  ArticleCategory,
  Comment,
  CommentableType,
  Editor,
  Game,
  GameMarks,
  IsArticleSaved,
  IsEditorFollowed,
  IsGameSaved,
  IsMagazineSaved,
  IsVideoSaved,
  Magazine,
  Section,
  StaticPage,
  Tag,
  User,
  Video,
} from "utils/api/models";
import {
  articleCategoriesKeys,
  articlesKeys,
  commentsKeys,
  editorsKeys,
  gameMarksKeys,
  gamesKeys,
  magazinesKeys,
  searchKeys,
  sectionsKeys,
  staticPagesKeys,
  tagsKeys,
  usersKeys,
  videosKeys,
} from "utils/api/queryKeys";

import { PaginatedResults } from "./index";

export function useArticleCategoriesQuery(
  queryParams?: ArticleCategoriesQueryParams
): UseQueryResult<PaginatedResults<ArticleCategory>> {
  return useQuery<PaginatedResults<ArticleCategory>, Error>(
    queryParams
      ? articleCategoriesKeys.filtered(queryParams)
      : articleCategoriesKeys.all,
    () => fetchArticleCategories(queryParams)
  );
}

export function useArticleCategoryQuery(
  slug: string
): UseQueryResult<ArticleCategory> {
  return useQuery<ArticleCategory, Error>(articleCategoriesKeys.one(slug), () =>
    fetchArticleCategory(slug)
  );
}

export function useArticlesQuery(
  queryParams?: ArticlesQueryParams
): UseQueryResult<PaginatedResults<Article>> {
  return useQuery<PaginatedResults<Article>, Error>(
    queryParams ? articlesKeys.filtered(queryParams) : articlesKeys.all,
    () => fetchArticles(queryParams)
  );
}

export function useArticleQuery(slug: string): UseQueryResult<Article> {
  return useQuery<Article, Error>(articlesKeys.one(slug), () =>
    fetchArticle(slug)
  );
}

export function useIsArticleSavedQuery(
  slug: string
): UseQueryResult<IsArticleSaved> {
  return useQuery<IsArticleSaved, Error>(
    articlesKeys.oneDetailed(slug, "is-saved"),
    () => fetchIsArticleSaved(slug),
    {
      retry: false,
    }
  );
}

export function useSaveArticleMutation(): UseMutationResult<
  Article,
  Error,
  SaveArticleMutationParams
> {
  return useMutation<Article, Error, SaveArticleMutationParams>(
    ({ slug }: SaveArticleMutationParams) => saveArticle(slug)
  );
}

export function useUnsaveArticleMutation(): UseMutationResult<
  Article,
  Error,
  SaveArticleMutationParams
> {
  return useMutation<Article, Error, SaveArticleMutationParams>(
    ({ slug }: SaveArticleMutationParams) => unsaveArticle(slug)
  );
}

export function useSetArticleAsReadMutation(slug: string): UseMutationResult {
  return useMutation(() => setArticleAsRead(slug));
}

export function useGamesQuery(
  queryParams?: GamesQueryParams
): UseQueryResult<PaginatedResults<Game>> {
  return useQuery<PaginatedResults<Game>, Error>(
    queryParams ? gamesKeys.filtered(queryParams) : gamesKeys.all,
    () => fetchGames(queryParams)
  );
}

export function useGameQuery(slug: string): UseQueryResult<Game> {
  return useQuery<Game, Error>(gamesKeys.one(slug), () => fetchGame(slug));
}

export function useUserGameMarksQuery(slug: string): UseQueryResult<GameMarks> {
  return useQuery<GameMarks, Error>(gameMarksKeys.one(slug), () =>
    fetchUserGameMarks(slug)
  );
}

export function useMarkGameMutation(
  slug: string
  //FIXME: znaleźć odpowiednie typowanie
): UseMutationResult<void, Error, any> {
  return useMutation<void, Error, any>(
    (
      collection: "favourite" | "considered" | "owned" | "played" | "completed"
    ) => markGame(slug, collection)
  );
}

export function useUnmarkGameMutation(
  slug: string
  //FIXME: znaleźć odpowiednie typowanie
): UseMutationResult<void, Error, any> {
  return useMutation<void, Error, any>(
    (
      collection: "favourite" | "considered" | "owned" | "played" | "completed"
    ) => unmarkGame(slug, collection)
  );
}

export function useFollowGameMutation(slug: string): UseMutationResult {
  return useMutation(() => followGame(slug));
}

export function useUnfollowGameMutation(slug: string): UseMutationResult {
  return useMutation(() => unfollowGame(slug));
}

export function useIsGameSavedQuery(slug: string): UseQueryResult<IsGameSaved> {
  return useQuery<IsGameSaved, Error>(
    gamesKeys.oneDetailed(slug, "is-saved"),
    () => fetchIsGameSaved(slug),
    {
      retry: false,
    }
  );
}

export function useSaveGameMutation(): UseMutationResult<
  Game,
  Error,
  SaveGameMutationParams
> {
  return useMutation<Game, Error, SaveGameMutationParams>(
    ({ slug }: SaveGameMutationParams) => saveGame(slug)
  );
}

export function useUnsaveGameMutation(): UseMutationResult<
  Game,
  Error,
  SaveGameMutationParams
> {
  return useMutation<Game, Error, SaveGameMutationParams>(
    ({ slug }: SaveGameMutationParams) => unsaveGame(slug)
  );
}

export function useRateGameMutation(
  slug: string
): UseMutationResult<void, Error, GamesMutationParams> {
  return useMutation<void, Error, GamesMutationParams>(
    (params: GamesMutationParams) => rateGame(slug, params)
  );
}

export function useEditorsQuery(
  queryParams?: EditorsQueryParams
): UseQueryResult<PaginatedResults<Editor>> {
  return useQuery<PaginatedResults<Editor>, Error>(
    queryParams ? editorsKeys.filtered(queryParams) : editorsKeys.all,
    () => fetchEditors(queryParams)
  );
}

export function useEditorQuery(slug: string): UseQueryResult<Editor> {
  return useQuery<Editor, Error>(editorsKeys.one(slug), () =>
    fetchEditor(slug)
  );
}

export function useIsEditorFollowedQuery(
  slug: string
): UseQueryResult<IsEditorFollowed> {
  return useQuery<IsEditorFollowed, Error>(
    editorsKeys.oneDetailed(slug, "is-followed"),
    () => fetchIsEditorFollowed(slug),
    {
      retry: false,
    }
  );
}

export function useUserQuery(slug: string): UseQueryResult<User> {
  return useQuery<User, Error>(usersKeys.one(slug), () => fetchUser(slug));
}

export function useAccountQuery(): UseQueryResult<User> {
  return useQuery<User, Error>(["account", "rich"], () => fetchAccount());
}

export function useAccountLazyQuery(): [() => void, UseQueryResult<User>] {
  const query = useQuery<User, Error>(
    ["account", "rich"],
    () => fetchAccount(),
    {
      enabled: false,
    }
  );

  return [query.refetch, query];
}

export function useBasicAccountLazyQuery(): [() => void, UseQueryResult<User>] {
  const query = useQuery<User, Error>(
    ["account", "basic"],
    () => fetchBasicAccount(),
    {
      enabled: false,
    }
  );

  return [query.refetch, query];
}

export function useTagsQuery(
  kind: string
): UseQueryResult<PaginatedResults<Tag>> {
  return useQuery<PaginatedResults<Tag>, Error>(
    tagsKeys.all,
    () => fetchTags(kind),
    {}
  );
}

// FIXME: uzupełnić typescript
export function useUpdateAccountMutation(): UseMutationResult<
  User,
  Error,
  any
> {
  return useMutation<User, Error, any>(usersKeys.all, (formData: any) =>
    updateAccount(formData)
  );
}

export function useEditorArticlesQuery(
  editorSlug: string,
  articleCategorySlug?: string,
  queryParams?: ArticlesQueryParams
): UseQueryResult<Article[]> {
  return useQuery<Article[], Error>(articlesKeys.one(editorSlug), () =>
    fetchEditorArticles(editorSlug, articleCategorySlug, queryParams)
  );
}

export function useFollowEditorMutation(slug: string): UseMutationResult {
  return useMutation(() => followEditor(slug));
}

export function useUnfollowEditorMutation(slug: string): UseMutationResult {
  return useMutation(() => unfollowEditor(slug));
}

function getCommentsQueryFetchFunctionByCommentableType(
  commentableType: CommentableType
) {
  switch (commentableType) {
    case CommentableType.Article:
      return fetchArticleComments;

    case CommentableType.Video:
      return fetchVideoComments;

    case CommentableType.Game:
      return fetchGameComments;
  }
}

export function useCommentsQuery(
  commentableType: CommentableType,
  commentableSlugOrId: string,
  queryParams?: CommentsQueryParams
): UseQueryResult<PaginatedResults<Comment>> {
  return useQuery<PaginatedResults<Comment>, Error>(
    commentsKeys.for(commentableType, commentableSlugOrId, queryParams),
    () =>
      getCommentsQueryFetchFunctionByCommentableType(commentableType)(
        commentableSlugOrId,
        queryParams
      )
  );
}

function getCreateCommentMutationFetchFunctionByCommentableType(
  commentableType: CommentableType
) {
  switch (commentableType) {
    case CommentableType.Article:
      return createArticleComment;

    case CommentableType.Video:
      return createVideoComment;

    case CommentableType.Game:
      return createGameComment;
  }
}

export function useCreateCommentMutation(
  commentableType: CommentableType,
  commentableSlugOrId: string
): UseMutationResult<Comment, Error, CreateCommentMutationParams> {
  return useMutation<Comment, Error, CreateCommentMutationParams>(
    (params: CreateCommentMutationParams) =>
      getCreateCommentMutationFetchFunctionByCommentableType(commentableType)(
        commentableSlugOrId,
        params
      )
  );
}

export function useUpvoteCommentMutation(): UseMutationResult<
  Comment,
  Error,
  UpvoteCommentMutationParams
> {
  return useMutation<Comment, Error, UpvoteCommentMutationParams>(
    ({ id }: UpvoteCommentMutationParams) => upvoteComment(id)
  );
}

export function useUnupvoteCommentMutation(): UseMutationResult<
  Comment,
  Error,
  UnupvoteCommentMutationParams
> {
  return useMutation<Comment, Error, UnupvoteCommentMutationParams>(
    ({ id }: UnupvoteCommentMutationParams) => unupvoteComment(id)
  );
}

export function useReportCommentMutation(): UseMutationResult<
  Comment,
  Error,
  ReportCommentMutationParams
> {
  return useMutation<Comment, Error, ReportCommentMutationParams>(
    ({ id, reason }: ReportCommentMutationParams) => reportComment(id, reason)
  );
}

export function useEditCommentMutation(): UseMutationResult<
  Comment,
  Error,
  EditCommentMutationParams
> {
  return useMutation<Comment, Error, EditCommentMutationParams>(
    ({ id, content }: EditCommentMutationParams) => editComment(id, content)
  );
}

export function useMagazinesQuery(
  queryParams?: MagazinesQueryParams
): UseQueryResult<PaginatedResults<Magazine>> {
  return useQuery<PaginatedResults<Magazine>, Error>(
    queryParams ? magazinesKeys.filtered(queryParams) : magazinesKeys.all,
    () => fetchMagazines(queryParams)
  );
}

export function useMagazineQuery(id: string): UseQueryResult<Magazine> {
  return useQuery<Magazine, Error>(magazinesKeys.one(id), () =>
    fetchMagazine(id)
  );
}

export function useIsMagazineSavedQuery(
  id: string
): UseQueryResult<IsMagazineSaved> {
  return useQuery<IsMagazineSaved, Error>(
    magazinesKeys.oneDetailed(id, "is-saved"),
    () => fetchIsMagazineSaved(id),
    {
      retry: false,
    }
  );
}

export function useLatestMagazineQuery(): UseQueryResult<Magazine> {
  return useQuery<Magazine, Error>(magazinesKeys.one("latest"), () =>
    fetchLatestMagazine()
  );
}

export function useSaveMagazineMutation(): UseMutationResult<
  Magazine,
  Error,
  SaveMagazineMutationParams
> {
  return useMutation<Magazine, Error, SaveMagazineMutationParams>(
    ({ id }: SaveMagazineMutationParams) => saveMagazine(id)
  );
}

export function useUnsaveMagazineMutation(): UseMutationResult<
  Magazine,
  Error,
  SaveMagazineMutationParams
> {
  return useMutation<Magazine, Error, SaveMagazineMutationParams>(
    ({ id }: SaveMagazineMutationParams) => unsaveMagazine(id)
  );
}

export function useVideosQuery(
  queryParams?: VideosQueryParams
): UseQueryResult<PaginatedResults<Video>> {
  return useQuery<PaginatedResults<Video>, Error>(
    queryParams ? videosKeys.filtered(queryParams) : videosKeys.all,
    () => fetchVideos(queryParams)
  );
}

export function useVideoQuery(slug: string): UseQueryResult<Video> {
  return useQuery<Video, Error>(videosKeys.one(slug), () => fetchVideo(slug));
}

export function useIsVideoSavedQuery(
  slug: string
): UseQueryResult<IsVideoSaved> {
  return useQuery<IsVideoSaved, Error>(
    videosKeys.oneDetailed(slug, "is-saved"),
    () => fetchIsVideoSaved(slug),
    {
      retry: false,
    }
  );
}

export function useSaveVideoMutation(): UseMutationResult<
  Video,
  Error,
  SaveVideoMutationParams
> {
  return useMutation<Video, Error, SaveVideoMutationParams>(
    ({ slug }: SaveVideoMutationParams) => saveVideo(slug)
  );
}

export function useUnsaveVideoMutation(): UseMutationResult<
  Video,
  Error,
  SaveVideoMutationParams
> {
  return useMutation<Video, Error, SaveVideoMutationParams>(
    ({ slug }: SaveVideoMutationParams) => unsaveVideo(slug)
  );
}

export function useLoginMutation(): UseMutationResult<
  { accessToken: string; user: User },
  Error,
  LoginMutationParams
> {
  return useMutation<
    { accessToken: string; user: User },
    Error,
    LoginMutationParams
  >(["auth"], (params: LoginMutationParams) => login(params));
}

export function useSearchQuery(
  keyword: string,
  model?: "Article" | "Game" | "User" | "Editor",
  queryParams?: SearchQueryParams,
  enabled?: boolean
): UseQueryResult<
  SearchResponse | PaginatedResults<Article | Game | User | Editor>
> {
  return useQuery<
    SearchResponse | PaginatedResults<Article | Game | User | Editor>,
    Error
  >(
    queryParams
      ? searchKeys.oneFiltered(model ? keyword + model : keyword, queryParams)
      : searchKeys.one(model ? keyword + model : keyword),
    () => search(keyword, model, queryParams),
    { enabled }
  );
}

export function useStaticPagesQuery(
  queryParams?: StaticPagesQueryParamas
): UseQueryResult<PaginatedResults<StaticPage>> {
  return useQuery<PaginatedResults<StaticPage>, Error>(
    queryParams ? staticPagesKeys.filtered(queryParams) : gamesKeys.all,
    () => fetchStaticPages(queryParams)
  );
}

export function useStaticPageQuery(slug: string): UseQueryResult<StaticPage> {
  return useQuery<StaticPage, Error>(staticPagesKeys.one(slug), () =>
    fetchStaticPage(slug)
  );
}

export function useSectionsQuery(): UseQueryResult<PaginatedResults<Section>> {
  return useQuery<PaginatedResults<Section>, Error>(
    sectionsKeys.all,
    fetchSections
  );
}
