import NextImage, { ImageProps as NextImageProps } from "next/image";
import { ReactElement, useMemo, useState } from "react";

type ImageProps = NextImageProps;

export default function Image({
  src,
  blurDataURL,
  ...restProps
}: ImageProps): ReactElement {
  // TODO: zaimplementować własny loader
  const [useFallback, setUseFallback] = useState(false);

  const newSrc = useMemo(() => {
    if (typeof src !== "string" || useFallback || src.startsWith("/")) {
      return src;
    }

    const url = new URL(src);
    url.searchParams.append("format", "webp");
    return url.toString();
  }, [src, useFallback]);

  const newBlurDataURL = useMemo(() => {
    if (!blurDataURL) {
      return undefined;
    }

    if (useFallback) {
      return blurDataURL;
    }

    const url = new URL(blurDataURL);
    url.searchParams.append("format", "webp");
    return url.toString();
  }, [blurDataURL, useFallback]);

  return (
    <NextImage
      src={newSrc}
      blurDataURL={newBlurDataURL}
      quality={100}
      onError={() => setUseFallback(true)}
      {...restProps}
    />
  );
}
