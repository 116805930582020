import { User } from ".";

export enum CommentableType {
  Article = "Article",
  Video = "Video",
  Game = "Game",
}

export type Comment = {
  id: number;
  content: string;
  upvoteCount: number;
  isHidden: boolean;
  isFeatured: boolean;
  commentableId: number;
  author: User;
  createdAt: string;
  hasCurrentUserUpvoted?: boolean;
  hasCurrentUserReported?: boolean;
  children?: Comment[];
  parentId?: number;
};
