import {
  ForwardedRef,
  forwardRef,
  HTMLProps,
  ReactElement,
  ReactHTML,
  ReactNode,
} from "react";
import { composeClassName } from "utils/misc";

export enum ButtonSize {
  XXLarge = "xx-large",
  XLarge = "x-large",
  Large = "large",
  Medium = "medium",
  Small = "small",
}

export enum ButtonKind {
  Minimal = "minimal",
}

type ButtonProps = {
  $as?: keyof ReactHTML;
  className?: string;
  icon?: any;
  isIconFirst?: boolean;
  size?: ButtonSize;
  kind?: ButtonKind;
  children?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardedRef?: ForwardedRef<any>;
};

export default function Button({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  $as = "button",
  className,
  icon,
  isIconFirst = false,
  children,
  size,
  kind,
  forwardedRef,
  ...props
}: ButtonProps & Omit<HTMLProps<typeof $as>, keyof ButtonProps>): ReactElement {
  return (
    <$as
      ref={forwardedRef}
      className={composeClassName(
        "font-bold font-mono flex items-center text-center justify-center uppercase disabled:cursor-default",
        size !== ButtonSize.XXLarge &&
          size !== ButtonSize.XLarge &&
          size !== ButtonSize.Medium &&
          "py-1",
        size === ButtonSize.XLarge && "py-3",
        size === ButtonSize.Medium && "py-2 px-4 disabled:opacity-60",
        size !== ButtonSize.Small &&
          size !== ButtonSize.XXLarge &&
          "text-gray-500 border-gray-500 hover:bg-gray-500 dark:hover:bg-gray-500 focus-visible:bg-gray-500 active:text-white focus-visible:text-white hover:text-white dark:hover:text-white active:bg-gray-600",
        size === ButtonSize.Large ||
          size === ButtonSize.XLarge ||
          size === ButtonSize.XXLarge ||
          size === ButtonSize.Medium
          ? "text-xs rounded-lg"
          : "text-2xs rounded",
        size === ButtonSize.XXLarge && "py-13px pl-14px pr-5",
        size === ButtonSize.Small && "py-0",
        icon && !children && size === ButtonSize.Large && "w-6 h-6",
        !(icon && !children && size === ButtonSize.Large) &&
          size !== ButtonSize.Medium &&
          size !== ButtonSize.XXLarge &&
          "px-2",
        kind === ButtonKind.Minimal
          ? !className?.includes("border") && "border-0"
          : "border-2",

        className
      )}
      {...props}
    >
      {!isIconFirst && children}
      {icon && (
        <span
          className={composeClassName(
            "inline-block",
            size === ButtonSize.Large && "h-3 children:h-full",
            !!children && !isIconFirst && "ml-2"
          )}
        >
          {icon}
        </span>
      )}
      {isIconFirst && children}
    </$as>
  );
}

// eslint-disable-next-line react/display-name
export const ForwardedButton = forwardRef(
  (
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      $as = "button",
      ...restProps
    }: ButtonProps & Omit<HTMLProps<typeof $as>, keyof ButtonProps>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: ForwardedRef<any>
  ) => <Button forwardedRef={ref} $as={$as} {...restProps} />
);
