import Footer from "components/Footer";
import { NextPage } from "next";
import type { ReactChild, ReactElement, ReactNode } from "react";
import { Fragment } from "react";
import { composeClassName } from "utils/misc";

import Header from "../Header";

type LayoutProps = {
  children?: ReactChild | NextPage;
  topAds?: ReactNode;
};

function Layout({ children, topAds }: LayoutProps): ReactElement {
  return (
    <Fragment>
      {topAds}

      <Header />

      <main
        id="maincontainer"
        className={composeClassName(
          "pt-0.5 overflow-hidden lg:overflow-visible"
        )}
      >
        {children}
      </main>

      <Footer />
    </Fragment>
  );
}

export default Layout;
