import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";

import { useAuth } from "./auth-context";

type TransitionContextProps = {
  isTransitioning: boolean;
};

const TransitionContext = React.createContext({} as TransitionContextProps);

export function TransitionProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const router = useRouter();

  const { isAuthenticated } = useAuth();

  function onRouteChangeStart() {
    setIsTransitioning(true);

    googletag.cmd.push(function () {
      googletag.pubads().clearTargeting();
      googletag.pubads().clear();
      googletag.destroySlots();
    });

    (window as any).dataLayer?.push(function (this: any) {
      this.reset();
    });
  }

  function onRouteChangeComplete() {
    setIsTransitioning(false);

    if (typeof window !== "undefined") {
      (window as any).dataLayer?.push({
        event: "Pageview",
        pagePath: window.location.href,
        pageTitle: window.location.href,
        visitorType: isAuthenticated ? "logged" : "new",
      });

      window.document.body.removeAttribute("style");
      (window as any).gemius_hit(
        "dwM7ywrDeGGwCIxa822lcbdMrhykXI7q5DWdmTkXCof.c7"
      );
    }
  }

  useEffect(() => {
    router.events.on("routeChangeStart", onRouteChangeStart);
    router.events.on("routeChangeComplete", onRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, []);

  return (
    <TransitionContext.Provider
      value={{
        isTransitioning,
      }}
    >
      {children}
    </TransitionContext.Provider>
  );
}

export const useTransition = (): TransitionContextProps =>
  useContext(TransitionContext);
