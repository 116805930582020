import ArticleCard, { ArticleCardVariant } from "components/ArticleCard";
import Carousel from "components/Carousel";
import type {
  DetailedHTMLProps,
  HtmlHTMLAttributes,
  ReactElement,
} from "react";
import { Fragment } from "react";
import { useArticlesQuery } from "utils/api/hooks";

export enum CategoryWidgetVariant {
  // Default
  Default = "default",

  // Carousel
  Carousel = "carousel",
}

type CategoryWidgetProps = {
  variant?: CategoryWidgetVariant;
  categoryId: number | string;
  limit?: number;
} & DetailedHTMLProps<HtmlHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function CategoryWidget({
  variant = CategoryWidgetVariant.Default,
  categoryId,
  limit,
  className,
  onClick,
}: CategoryWidgetProps): ReactElement {
  const articlesQuery = useArticlesQuery({
    categoryId,
    limit,
    sort: "-publishedAt",
  });

  return (
    <Fragment>
      {variant === CategoryWidgetVariant.Carousel ? (
        <div className={className}>
          <Carousel placeholder={ArticleCard}>
            {articlesQuery.data?.results.map((article, index) => (
              <ArticleCard
                onClick={onClick}
                key={`carousel-article-${index}`}
                article={article}
                variant={ArticleCardVariant.DefaultWithTwoLinesTitle}
              />
            ))}
          </Carousel>
        </div>
      ) : (
        <div className={className}>
          <ArticleCard
            onClick={onClick}
            article={articlesQuery.data?.results[0]}
            variant={ArticleCardVariant.DefaultWithTwoLinesTitle}
          />

          <ArticleCard
            onClick={onClick}
            article={articlesQuery.data?.results[1]}
            variant={ArticleCardVariant.DefaultWithTwoLinesTitle}
          />
        </div>
      )}
    </Fragment>
  );
}
