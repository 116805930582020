import { ContentPage } from ".";
import { Comment } from "./Comment";

export type Magazine = {
  id: string;
  lead: string;
  issueNumber: string;
  description: string;
  issuedOn: string;
  nextIssueOn: string;
  issueCoverUrl: string;
  issueCoverArtUrl: string;
  bannerUrl: string;
  paperIssuePrice: number;
  digitalIssuePrice: number;
  body: ContentPage[];
  readTime: number;
  status: MagazineStatus;
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  comments: Comment[];
  commentCount: number;
  storeUrl?: string;
  trackingCode?: string;
};

export enum MagazineStatus {
  Draft = "Draft",
  Pending = "Pending",
  Published = "Published",
}

export type IsMagazineSaved = {
  isSaved: boolean;
};
