import { useTheme } from "next-themes";
import type { ReactElement } from "react";
import { useEffect } from "react";
import { composeClassName } from "utils/misc";

import { Moon, Sun } from "../Icons";

export enum ThemeSwitchKind {
  LoginPage = "login-page",
}

type Props = {
  kind?: ThemeSwitchKind;
};

export default function ThemeSwitch({ kind }: Props): ReactElement {
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    document.body.dataset.theme = theme;
  }, [theme]);

  return (
    <button
      aria-label="Set light/dark theme"
      className="flex flex-row"
      onClick={() => setTheme(theme === "light" ? "dark" : "light")}
    >
      <div
        className={composeClassName(
          "flex items-center justify-center w-6 h-6 border border-r-0 rounded-l rounded-r-none cursor-pointer dark:bg-transparent dark:border-orange",
          kind === ThemeSwitchKind.LoginPage
            ? "bg-white border-white lg:bg-gray-500 lg:border-gray-500"
            : "bg-gray-500 border-gray-500"
        )}
      >
        <Sun
          className={composeClassName(
            "w-3 h-3 fill-transparent dark:stroke-orange dark:fill-orange",
            kind === ThemeSwitchKind.LoginPage && "stroke-black lg:stroke-white"
          )}
        />
      </div>

      <div
        className={composeClassName(
          "flex items-center justify-center w-6 h-6 border border-l-0 rounded-l-none rounded-r cursor-pointer dark:border-orange dark:bg-orange",
          kind === ThemeSwitchKind.LoginPage
            ? "border-white lg:bg-white lg:border-gray-500"
            : "border-gray-500 bg-white"
        )}
      >
        <Moon
          className={composeClassName(
            "w-3 h-3 fill-transparent dark:stroke-black-200",
            kind === ThemeSwitchKind.LoginPage &&
              "stroke-white lg:stroke-gray-500"
          )}
        />
      </div>
    </button>
  );
}
