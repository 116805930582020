import { LinkProps } from "next/link";
import { cloneElement, PropsWithChildren, ReactElement } from "react";

export function composeClassName(
  ...classNames: (string | undefined | boolean)[]
): string {
  return classNames.filter(Boolean).join(" ");
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isReactElement(object: any): object is ReactElement {
  return object["$$typeof"] === Symbol.for("react.element");
}

export function passPropsThroughLink<PropsType>(
  element: ReactElement<PropsType | PropsWithChildren<LinkProps>>,
  props: PropsType
): PropsType | PropsWithChildren<LinkProps> {
  if (typeof element.type === "function" && element.type.name === "Link") {
    // Cast props to Link's props as we are now pretty sure it is a Link component instance
    const linkProps = element.props as PropsWithChildren<LinkProps>;
    const child = linkProps.children;

    if (isReactElement(child)) {
      return {
        ...element.props,
        children: cloneElement(child, {
          ...props,
        }),
      };
    }
  }

  return props;
}

export function escapeHtml(unsafe: string): string {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;")
    .replace(/:/g, "%3A")
    .replace(/\//g, "%2F");
}

export function declinateMinutes(number: number): string {
  if (number == 1) {
    return "minutę";
  }
  if (
    number % 10 > 1 &&
    number % 10 < 5 &&
    !(number % 100 >= 10 && number % 100 <= 21)
  ) {
    return "minuty";
  }

  return "minut";
}

export function roundTo1DecimalPlace(number: unknown): number | string {
  if (typeof number === "number") {
    return +number.toFixed(1);
  }
  return "-";
}

export enum ViewType {
  Grid,
  List,
}

export function setPreferredViewType(context: string, view: ViewType): void {
  if (typeof window !== "undefined" && localStorage) {
    localStorage.setItem(`preferred-view-${context}`, ViewType[view]);
  }
}

export function getPreferredViewType(context: string): ViewType {
  const preferredViewType =
    typeof window !== "undefined" &&
    localStorage &&
    localStorage.getItem(`preferred-view-${context}`);

  return preferredViewType
    ? (<any>ViewType)[preferredViewType as string]
    : ViewType.Grid;
}

export function secondsToHHMMSS(seconds: number): string {
  return (
    (Math.floor(seconds / 3600) > 0 ? Math.floor(seconds / 3600) + ":" : "") +
    ("0" + (Math.floor(seconds / 60) % 60)).slice(-2) +
    ":" +
    ("0" + (seconds % 60)).slice(-2)
  );
}

export function snakeCase(string?: string): string | undefined {
  return string
    ?.split(/(?=[A-Z])/)
    ?.join("_")
    ?.toLowerCase();
}

export function decode(string?: string): string {
  const entitiesMap = {
    nbsp: " ",
    iexcl: "¡",
    cent: "¢",
    pound: "£",
    curren: "¤",
    yen: "¥",
    brvbar: "¦",
    sect: "§",
    uml: "¨",
    copy: "©",
    ordf: "ª",
    laquo: "«",
    not: "¬",
    reg: "®",
    macr: "¯",
    deg: "°",
    plusmn: "±",
    sup2: "²",
    sup3: "³",
    acute: "´",
    micro: "µ",
    para: "¶",
    middot: "·",
    cedil: "¸",
    sup1: "¹",
    ordm: "º",
    raquo: "»",
    frac14: "¼",
    frac12: "½",
    frac34: "¾",
    iquest: "¿",
    Agrave: "À",
    Aacute: "Á",
    Acirc: "Â",
    Atilde: "Ã",
    Auml: "Ä",
    Aring: "Å",
    AElig: "Æ",
    Ccedil: "Ç",
    Egrave: "È",
    Eacute: "É",
    Ecirc: "Ê",
    Euml: "Ë",
    Igrave: "Ì",
    Iacute: "Í",
    Icirc: "Î",
    Iuml: "Ï",
    ETH: "Ð",
    Ntilde: "Ñ",
    Ograve: "Ò",
    Oacute: "Ó",
    Ocirc: "Ô",
    Otilde: "Õ",
    Ouml: "Ö",
    times: "×",
    Oslash: "Ø",
    Ugrave: "Ù",
    Uacute: "Ú",
    Ucirc: "Û",
    Uuml: "Ü",
    Yacute: "Ý",
    THORN: "Þ",
    szlig: "ß",
    agrave: "à",
    aacute: "á",
    acirc: "â",
    atilde: "ã",
    auml: "ä",
    aring: "å",
    aelig: "æ",
    ccedil: "ç",
    egrave: "è",
    eacute: "é",
    ecirc: "ê",
    euml: "ë",
    igrave: "ì",
    iacute: "í",
    icirc: "î",
    iuml: "ï",
    eth: "ð",
    ntilde: "ñ",
    ograve: "ò",
    oacute: "ó",
    ocirc: "ô",
    otilde: "õ",
    ouml: "ö",
    divide: "÷",
    oslash: "ø",
    ugrave: "ù",
    uacute: "ú",
    ucirc: "û",
    uuml: "ü",
    yacute: "ý",
    thorn: "þ",
    yuml: "ÿ",
    fnof: "ƒ",
    Alpha: "Α",
    Beta: "Β",
    Gamma: "Γ",
    Delta: "Δ",
    Epsilon: "Ε",
    Zeta: "Ζ",
    Eta: "Η",
    Theta: "Θ",
    Iota: "Ι",
    Kappa: "Κ",
    Lambda: "Λ",
    Mu: "Μ",
    Nu: "Ν",
    Xi: "Ξ",
    Omicron: "Ο",
    Pi: "Π",
    Rho: "Ρ",
    Sigma: "Σ",
    Tau: "Τ",
    Upsilon: "Υ",
    Phi: "Φ",
    Chi: "Χ",
    Psi: "Ψ",
    Omega: "Ω",
    alpha: "α",
    beta: "β",
    gamma: "γ",
    delta: "δ",
    epsilon: "ε",
    zeta: "ζ",
    eta: "η",
    theta: "θ",
    iota: "ι",
    kappa: "κ",
    lambda: "λ",
    mu: "μ",
    nu: "ν",
    xi: "ξ",
    omicron: "ο",
    pi: "π",
    rho: "ρ",
    sigmaf: "ς",
    sigma: "σ",
    tau: "τ",
    upsilon: "υ",
    phi: "φ",
    chi: "χ",
    psi: "ψ",
    omega: "ω",
    thetasym: "ϑ",
    upsih: "ϒ",
    piv: "ϖ",
    bull: "•",
    hellip: "…",
    prime: "′",
    Prime: "″",
    oline: "‾",
    frasl: "⁄",
    weierp: "℘",
    image: "ℑ",
    real: "ℜ",
    trade: "™",
    alefsym: "ℵ",
    larr: "←",
    uarr: "↑",
    rarr: "→",
    darr: "↓",
    harr: "↔",
    crarr: "↵",
    lArr: "⇐",
    uArr: "⇑",
    rArr: "⇒",
    dArr: "⇓",
    hArr: "⇔",
    forall: "∀",
    part: "∂",
    exist: "∃",
    empty: "∅",
    nabla: "∇",
    isin: "∈",
    notin: "∉",
    ni: "∋",
    prod: "∏",
    sum: "∑",
    minus: "−",
    lowast: "∗",
    radic: "√",
    prop: "∝",
    infin: "∞",
    ang: "∠",
    and: "∧",
    or: "∨",
    cap: "∩",
    cup: "∪",
    int: "∫",
    there4: "∴",
    sim: "∼",
    cong: "≅",
    asymp: "≈",
    ne: "≠",
    equiv: "≡",
    le: "≤",
    ge: "≥",
    sub: "⊂",
    sup: "⊃",
    nsub: "⊄",
    sube: "⊆",
    supe: "⊇",
    oplus: "⊕",
    otimes: "⊗",
    perp: "⊥",
    sdot: "⋅",
    lceil: "⌈",
    rceil: "⌉",
    lfloor: "⌊",
    rfloor: "⌋",
    lang: "〈",
    rang: "〉",
    loz: "◊",
    spades: "♠",
    clubs: "♣",
    hearts: "♥",
    diams: "♦",
    '"': "quot",
    amp: "&",
    // lt: "<",
    // gt: ">",
    OElig: "Œ",
    oelig: "œ",
    Scaron: "Š",
    scaron: "š",
    Yuml: "Ÿ",
    circ: "ˆ",
    tilde: "˜",
    ndash: "–",
    mdash: "—",
    lsquo: "‘",
    rsquo: "’",
    sbquo: "‚",
    ldquo: "“",
    rdquo: "”",
    bdquo: "„",
    dagger: "†",
    Dagger: "‡",
    permil: "‰",
    lsaquo: "‹",
    rsaquo: "›",
    euro: "€",
  };

  const entitiesMapExp = new RegExp(
    "&(" + Object.keys(entitiesMap).join("|") + ");",
    "g"
  );

  return string
    ? string.replace(entitiesMapExp, function (x) {
        return (entitiesMap as any)[x.substring(1, x.length - 1)] || x;
      })
    : "";
}
