import "styles/index.css";
import "styles/DatePicker.css";

import ExitPreviewPrompt from "components/ExitPreviewPrompt";
import Layout from "components/Layout";
import { AuthProvider } from "contexts/auth-context";
import { NotificationModalProvider } from "contexts/notification-modal-context";
import { ProfileModalProvider } from "contexts/profile-modal-context";
import { TransitionProvider } from "contexts/transition-context";
import { NextPage } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import { ThemeProvider } from "next-themes";
import { Fragment, ReactElement, ReactNode, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Hydrate } from "react-query/hydration";
import { BasePageProps } from "utils/types";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type CDAWebAppProps<P> = {
  pageProps: P;
  Component: NextPageWithLayout;
} & Omit<AppProps<P>, "pageProps">;

function CDAWebApp({
  Component,
  pageProps,
}: CDAWebAppProps<BasePageProps>): React.ReactNode {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: { staleTime: Infinity },
        },
      })
  );

  const [cookies] = useCookies(["__cda_preview_mode"]);

  useEffect(() => {
    if (!("theme" in localStorage)) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    }
  }, []);

  useEffect(() => {
    if (!pageProps?.preview && cookies.__cda_preview_mode) {
      window.location.href = "/api/exit-preview";
    }
  }, [pageProps, cookies]);

  const getLayout = Component.getLayout
    ? Component.getLayout.bind(null, pageProps as any)
    : (page: ReactElement) => <Layout>{page}</Layout>;

  return (
    <Fragment>
      <Head>
        <title>CD-Action</title>
      </Head>

      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ProfileModalProvider>
            <NotificationModalProvider>
              <Hydrate state={pageProps.dehydratedState}>
                <ThemeProvider attribute="class">
                  <TransitionProvider>
                    {getLayout(<Component {...pageProps} />)}

                    {pageProps.preview && (
                      <ExitPreviewPrompt previewData={pageProps?.previewData} />
                    )}
                  </TransitionProvider>
                </ThemeProvider>

                <ReactQueryDevtools initialIsOpen={false} />
              </Hydrate>
            </NotificationModalProvider>
          </ProfileModalProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Fragment>
  );
}

export default CDAWebApp;
