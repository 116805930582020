import axios from "axios";
import { ProfileFormDefaultValue } from "sections/ProfileEditModal/profile-form";
import {
  Article,
  ArticleCategory,
  Comment,
  Editor,
  Game,
  GameMarks,
  IsArticleSaved,
  IsEditorFollowed,
  IsGameSaved,
  IsMagazineSaved,
  IsVideoSaved,
  Magazine,
  Section,
  StaticPage,
  Tag,
  User,
  Video,
} from "utils/api/models";
import { AUTH_TOKEN } from "utils/constants";
import { CommentsSorter, DefaultSorter } from "utils/sorters";

const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
const apiBasicAuthUsername = process.env.NEXT_PUBLIC_API_BASIC_AUTH_USERNAME;
const apiBasicAuthPassword = process.env.NEXT_PUBLIC_API_BASIC_AUTH_PASSWORD;

export const apiClient = axios.create({
  baseURL: apiBaseUrl,
  ...(apiBasicAuthUsername &&
    apiBasicAuthPassword && {
      auth: {
        username: apiBasicAuthUsername,
        password: apiBasicAuthPassword,
      },
    }),
  headers: {
    ...(typeof window !== "undefined" &&
      window.localStorage.getItem(AUTH_TOKEN) && {
        "user-authorization": window.localStorage.getItem(AUTH_TOKEN),
      }),
  },
});

type BaseQueryParams = {
  offset?: number;
  limit?: number;
  sort?: string | DefaultSorter | CommentsSorter;
};

export type PaginatedResults<T> = {
  totalCount: number;
  results: T[];
};

export type SearchResponse = {
  gameCount: number;
  games: Game[];
  articleCount: number;
  articles: Article[];
  userCount: number;
  users: User[];
  editorCount: number;
  editors: Editor[];
};

// Article Categories
export type ArticleCategoriesQueryParams = BaseQueryParams;

export async function fetchArticleCategories(
  params?: ArticleCategoriesQueryParams
): Promise<PaginatedResults<ArticleCategory>> {
  const { data } = await apiClient.get<PaginatedResults<ArticleCategory>>(
    "/article-categories",
    {
      params,
    }
  );

  return data;
}

export async function fetchArticleCategory(
  slug: string
): Promise<ArticleCategory> {
  const { data } = await apiClient.get<ArticleCategory>(
    `/article-categories/${slug}`
  );

  return data;
}

// Articles
export type ArticlesQueryParams = BaseQueryParams & {
  categoryId?: number | string;
  isFeatured?: boolean;
  isRecommended?: boolean;
  isPinned?: boolean;
};

export type SaveArticleMutationParams = {
  slug: string;
};

export async function fetchArticles(
  params?: ArticlesQueryParams
): Promise<PaginatedResults<Article>> {
  const { data } = await apiClient.get<PaginatedResults<Article>>("/articles", {
    params,
  });

  return data;
}

export async function fetchArticle(slug: string): Promise<Article> {
  const { data } = await apiClient.get<Article>(`/articles/${slug}`);

  return data;
}

export async function fetchArticlePreview(
  slug: string,
  previewToken: string
): Promise<Article> {
  const { data } = await apiClient.get<Article>(`/articles/${slug}`, {
    params: { previewToken },
  });

  return data;
}

export async function fetchIsArticleSaved(
  slug: string
): Promise<IsArticleSaved> {
  const { data } = await apiClient.get<IsArticleSaved>(
    `/articles/${slug}/is-saved`
  );

  return data;
}

export async function saveArticle(slug: string): Promise<Article> {
  const { data } = await apiClient.post(`/articles/${slug}/save`);

  return data;
}

export async function unsaveArticle(slug: string): Promise<Article> {
  const { data } = await apiClient.post(`/articles/${slug}/unsave`);

  return data;
}

export async function setArticleAsRead(slug: string): Promise<void> {
  const { data } = await apiClient.post(`/articles/${slug}/read`);

  return data;
}

// Games
export type GamesQueryParams = { isFeatured?: boolean } & BaseQueryParams;

export type SaveGameMutationParams = {
  slug: string;
};

export type GamesMutationParams = {
  rating: number;
};

export async function fetchGames(
  params?: GamesQueryParams
): Promise<PaginatedResults<Game>> {
  const { data } = await apiClient.get<PaginatedResults<Game>>("/games", {
    params,
  });

  return data;
}

export async function fetchGame(slug: string): Promise<Game> {
  const { data } = await apiClient.get<Game>(`/games/${slug}`);

  return data;
}

export async function fetchUserGameMarks(slug: string): Promise<GameMarks> {
  const { data } = await apiClient.get<GameMarks>(`/games/${slug}/marks`);

  return data;
}

export async function markGame(
  slug: string,
  collection: "favourite" | "considered" | "owned" | "played" | "completed"
): Promise<void> {
  const { data } = await apiClient.post(`/games/${slug}/mark/${collection}`);

  return data;
}

export async function unmarkGame(
  slug: string,
  collection: "favourite" | "considered" | "owned" | "played" | "completed"
): Promise<void> {
  const { data } = await apiClient.post(`/games/${slug}/unmark/${collection}`);

  return data;
}

export async function followGame(slug: string): Promise<void> {
  const { data } = await apiClient.post(`/games/${slug}/follow`);

  return data;
}

export async function unfollowGame(slug: string): Promise<void> {
  const { data } = await apiClient.post(`/games/${slug}/unfollow`);

  return data;
}

export async function fetchIsGameSaved(slug: string): Promise<IsGameSaved> {
  const { data } = await apiClient.get<IsGameSaved>(`/games/${slug}/is-saved`);

  return data;
}

export async function saveGame(slug: string): Promise<Game> {
  const { data } = await apiClient.post(`/games/${slug}/save`);

  return data;
}

export async function unsaveGame(slug: string): Promise<Game> {
  const { data } = await apiClient.post(`/games/${slug}/unsave`);

  return data;
}

export async function rateGame(
  slug: string,
  params: GamesMutationParams
): Promise<void> {
  const { data } = await apiClient.post(`/games/${slug}/rate`, {
    ...params,
  });

  return data;
}

// Editors
export type EditorsQueryParams = { slug?: string } & BaseQueryParams;

export async function fetchEditors(
  params?: EditorsQueryParams
): Promise<PaginatedResults<Editor>> {
  const { data } = await apiClient.get<PaginatedResults<Editor>>("/editors", {
    params,
  });

  return data;
}

export async function fetchEditor(slug: string): Promise<Editor> {
  const [
    { data },
    { data: favouriteGames },
    { data: consideredGames },
    { data: ownedGames },
    { data: playedGames },
    { data: completedGames },
    { data: articles },
    { data: articleCategories },
  ] = await Promise.all([
    await apiClient.get<Editor>(`/editors/${slug}`),
    await apiClient.get<Game[]>(`/editors/${slug}/favourite-games`),
    await apiClient.get<Game[]>(`/editors/${slug}/considered-games`),
    await apiClient.get<Game[]>(`/editors/${slug}/owned-games`),
    await apiClient.get<Game[]>(`/editors/${slug}/played-games`),
    await apiClient.get<Game[]>(`/editors/${slug}/completed-games`),
    await apiClient.get<PaginatedResults<Article>>(
      `/editors/${slug}/articles`,
      { params: { sort: DefaultSorter.Newest } }
    ),
    await apiClient.get<ArticleCategory[]>(
      `/editors/${slug}/article-categories`
    ),
  ]);

  return {
    ...data,
    favouriteGames: favouriteGames ? favouriteGames : [],
    consideredGames: consideredGames ? consideredGames : [],
    ownedGames: ownedGames ? ownedGames : [],
    playedGames: playedGames ? playedGames : [],
    completedGames: completedGames ? completedGames : [],
    articles: articles ? articles.results : [],
    articleCategories: articleCategories ? articleCategories : [],
  };
}

export async function fetchEditorPreview(
  slug: string,
  previewToken: string
): Promise<Editor> {
  const [
    { data },
    { data: favouriteGames },
    { data: consideredGames },
    { data: ownedGames },
    { data: playedGames },
    { data: completedGames },
    { data: articles },
    { data: articleCategories },
  ] = await Promise.all([
    await apiClient.get<Editor>(`/editors/${slug}`, {
      params: { previewToken },
    }),
    await apiClient.get<Game[]>(`/editors/${slug}/favourite-games`, {
      params: { previewToken },
    }),
    await apiClient.get<Game[]>(`/editors/${slug}/considered-games`, {
      params: { previewToken },
    }),
    await apiClient.get<Game[]>(`/editors/${slug}/owned-games`, {
      params: { previewToken },
    }),
    await apiClient.get<Game[]>(`/editors/${slug}/played-games`, {
      params: { previewToken },
    }),
    await apiClient.get<Game[]>(`/editors/${slug}/completed-games`, {
      params: { previewToken },
    }),
    await apiClient.get<PaginatedResults<Article>>(
      `/editors/${slug}/articles`,
      {
        params: { previewToken },
      }
    ),
    await apiClient.get<ArticleCategory[]>(
      `/editors/${slug}/article-categories`,
      {
        params: { previewToken },
      }
    ),
  ]);

  return {
    ...data,
    favouriteGames,
    consideredGames,
    ownedGames,
    playedGames,
    completedGames,
    articles: articles ? articles.results : [],
    articleCategories,
  };
}

export async function fetchEditorArticles(
  editorSlug: string,
  articleCategorySlug?: string,
  params?: ArticlesQueryParams
): Promise<Article[]> {
  if (articleCategorySlug) {
    const { data } = await apiClient.get<Article[]>(
      `/editors/${editorSlug}/article-categories/${articleCategorySlug}/articles`,
      {
        params,
      }
    );

    return data;
  } else {
    const { data } = await apiClient.get<PaginatedResults<Article>>(
      `/editors/${editorSlug}/articles`,
      {
        params,
      }
    );

    return data.results;
  }
}

export async function followEditor(slug: string): Promise<void> {
  const { data } = await apiClient.post(`/editors/${slug}/follow`);

  return data;
}

export async function unfollowEditor(slug: string): Promise<void> {
  const { data } = await apiClient.post(`/editors/${slug}/unfollow`);

  return data;
}

export async function fetchIsEditorFollowed(
  slug: string
): Promise<IsEditorFollowed> {
  const { data } = await apiClient.get<IsEditorFollowed>(
    `/editors/${slug}/is-followed`
  );

  return data;
}

// Users
export type UsersQueryParams = BaseQueryParams;

export async function fetchUser(slug: string): Promise<User> {
  const [
    { data },
    { data: favouriteGames },
    { data: consideredGames },
    { data: ownedGames },
    { data: playedGames },
    { data: completedGames },
    { data: editors },
  ] = await Promise.all([
    await apiClient.get<User>(`/users/${slug}`),
    await apiClient.get<Game[]>(`/users/${slug}/favourite-games`),
    await apiClient.get<Game[]>(`/users/${slug}/considered-games`),
    await apiClient.get<Game[]>(`/users/${slug}/owned-games`),
    await apiClient.get<Game[]>(`/users/${slug}/played-games`),
    await apiClient.get<Game[]>(`/users/${slug}/completed-games`),
    await apiClient.get<Editor[]>(`/users/${slug}/editors`),
  ]);

  return {
    ...data,
    favouriteGames: favouriteGames ? favouriteGames : [],
    consideredGames: consideredGames ? consideredGames : [],
    ownedGames: ownedGames ? ownedGames : [],
    playedGames: playedGames ? playedGames : [],
    completedGames: completedGames ? completedGames : [],
    editors: editors ? editors : [],
  };
}

// Account
export async function fetchAccount(): Promise<User> {
  const [
    { data },
    { data: favouriteGames },
    { data: consideredGames },
    { data: ownedGames },
    { data: playedGames },
    { data: completedGames },
    { data: editors },
  ] = await Promise.all([
    await apiClient.get<User>(`/account`),
    await apiClient.get<Game[]>(`/account/favourite-games`),
    await apiClient.get<Game[]>(`/account/considered-games`),
    await apiClient.get<Game[]>(`/account/owned-games`),
    await apiClient.get<Game[]>(`/account/played-games`),
    await apiClient.get<Game[]>(`/account/completed-games`),
    await apiClient.get<Editor[]>(`/account/editors`),
  ]);

  return {
    ...data,
    favouriteGames: favouriteGames ? favouriteGames : [],
    consideredGames: consideredGames ? consideredGames : [],
    ownedGames: ownedGames ? ownedGames : [],
    playedGames: playedGames ? playedGames : [],
    completedGames: completedGames ? completedGames : [],
    editors: editors ? editors : [],
  };
}
export async function fetchBasicAccount(): Promise<User> {
  const { data } = await apiClient.get<User>(`/account`);

  return data;
}

export async function updateAccount(
  formData: ProfileFormDefaultValue
): Promise<User> {
  const { data } = await apiClient.patch<User>(`/account`, formData);

  return data;
}

// Tags
export type TagsQueryParams = BaseQueryParams;

export async function fetchTags(kind: string): Promise<PaginatedResults<Tag>> {
  const [{ data }] = await Promise.all([
    await apiClient.get<PaginatedResults<Tag>>(`/tags?kind=${kind}`),
  ]);

  return {
    ...data,
  };
}

// Comments

export type CommentsQueryParams = BaseQueryParams;
export type CommentsMutationParams = {
  content: string;
};

export type CreateCommentMutationParams = {
  content: string;
  parentId?: number;
};

export type EditCommentMutationParams = {
  id: number;
  content: string;
};

export type UpvoteCommentMutationParams = {
  id: number;
};

export type UnupvoteCommentMutationParams = {
  id: number;
};

export type ReportCommentMutationParams = {
  id: number;
  reason: string;
};

export async function createArticleComment(
  slug: string,
  params: CommentsMutationParams
): Promise<Comment> {
  const { data } = await apiClient.post<Comment>(
    `/articles/${slug}/comments`,
    params
  );

  return data;
}

export async function createGameComment(
  slug: string,
  params: CommentsMutationParams
): Promise<Comment> {
  const { data } = await apiClient.post<Comment>(
    `/games/${slug}/comments`,
    params
  );

  return data;
}

export async function createVideoComment(
  slug: string,
  params: CommentsMutationParams
): Promise<Comment> {
  const { data } = await apiClient.post<Comment>(
    `/videos/${slug}/comments`,
    params
  );

  return data;
}

export async function fetchArticleComments(
  slug: string,
  params?: CommentsQueryParams
): Promise<PaginatedResults<Comment>> {
  const { data } = await apiClient.get<PaginatedResults<Comment>>(
    `/articles/${slug}/comments`,
    {
      params,
    }
  );

  return data;
}

export async function fetchGameComments(
  slug: string,
  params?: CommentsQueryParams
): Promise<PaginatedResults<Comment>> {
  const { data } = await apiClient.get<PaginatedResults<Comment>>(
    `/games/${slug}/comments`,
    {
      params,
    }
  );

  return data;
}

export async function fetchVideoComments(
  slug: string,
  params?: CommentsQueryParams
): Promise<PaginatedResults<Comment>> {
  const { data } = await apiClient.get<PaginatedResults<Comment>>(
    `/videos/${slug}/comments`,
    {
      params,
    }
  );

  return data;
}

export async function upvoteComment(id: number): Promise<Comment> {
  const { data } = await apiClient.post(`/comments/${id}/upvote`);

  return data;
}

export async function unupvoteComment(id: number): Promise<Comment> {
  const { data } = await apiClient.post(`/comments/${id}/unupvote`);

  return data;
}

export async function reportComment(
  id: number,
  reason: string
): Promise<Comment> {
  const { data } = await apiClient.post(`/comments/${id}/report`, { reason });

  return data;
}

export async function editComment(
  id: number,
  content: string
): Promise<Comment> {
  const { data } = await apiClient.patch<Comment>(`/comments/${id}`, {
    content,
  });

  return data;
}

// Magazines
export type MagazinesQueryParams = BaseQueryParams;

export type SaveMagazineMutationParams = {
  id: string;
};

export async function fetchMagazines(
  params?: MagazinesQueryParams
): Promise<PaginatedResults<Magazine>> {
  const { data } = await apiClient.get<PaginatedResults<Magazine>>(
    "/magazines",
    {
      params,
    }
  );

  return data;
}

export async function fetchMagazine(id: string): Promise<Magazine> {
  const { data } = await apiClient.get<Magazine>(`/magazines/${id}`);

  return data;
}

export async function fetchIsMagazineSaved(
  id: string
): Promise<IsMagazineSaved> {
  const { data } = await apiClient.get<IsMagazineSaved>(
    `/magazines/${id}/is-saved`
  );

  return data;
}

export async function fetchLatestMagazine(): Promise<Magazine> {
  const { data } = await apiClient.get<Magazine>(`/magazines/latest`);

  return data;
}

export async function fetchMagazinePreview(
  id: string,
  previewToken: string
): Promise<Magazine> {
  const { data } = await apiClient.get<Magazine>(`/magazines/${id}`, {
    params: { previewToken },
  });

  return data;
}

export async function saveMagazine(id: string): Promise<Magazine> {
  const { data } = await apiClient.post(`/magazines/${id}/save`);

  return data;
}

export async function unsaveMagazine(id: string): Promise<Magazine> {
  const { data } = await apiClient.post(`/magazines/${id}/unsave`);

  return data;
}

// Videos
export type VideosQueryParams = {
  isFeaturedOnHomePage?: boolean;
  isBlockedFromHomePage?: boolean;
} & BaseQueryParams;

export type SaveVideoMutationParams = {
  slug: string;
};

export async function fetchVideos(
  params?: VideosQueryParams
): Promise<PaginatedResults<Video>> {
  const { data } = await apiClient.get<PaginatedResults<Video>>("/videos", {
    params,
  });

  return data;
}

export async function fetchVideo(slug: string): Promise<Video> {
  const { data } = await apiClient.get<Video>(`/videos/${slug}`);

  return data;
}

export async function fetchVideoPreview(
  slug: string,
  previewToken: string
): Promise<Video> {
  const { data } = await apiClient.get<Video>(`/videos/${slug}`, {
    params: { previewToken },
  });

  return data;
}

export async function fetchIsVideoSaved(slug: string): Promise<IsVideoSaved> {
  const { data } = await apiClient.get<IsVideoSaved>(
    `/videos/${slug}/is-saved`
  );

  return data;
}

export async function saveVideo(slug: string): Promise<Video> {
  const { data } = await apiClient.post(`/videos/${slug}/save`);

  return data;
}

export async function unsaveVideo(slug: string): Promise<Video> {
  const { data } = await apiClient.post(`/videos/${slug}/unsave`);

  return data;
}

// Auth
export type LoginMutationParams = {
  loginToken: string;
};

export async function login(
  params: LoginMutationParams
): Promise<{ accessToken: string; user: User }> {
  const { data } = await apiClient.post<{ accessToken: string; user: User }>(
    `/auth/login`,
    params
  );

  return data;
}

// Search
export type SearchQueryParams = {
  tagIds?: string;
} & BaseQueryParams;

export async function search(
  keyword: string,
  model?: "Article" | "Game" | "User" | "Editor",
  params?: SearchQueryParams
): Promise<SearchResponse | PaginatedResults<Article | Game | User | Editor>> {
  const { data } = await apiClient.get<SearchResponse>(
    model
      ? `/search/model/${model.toLowerCase()}/${keyword}`
      : `/search/${keyword}`,
    {
      params,
    }
  );

  return data;
}

// Old paths

export function createFetchByOriginalFieldFunction<Model>(
  resource: string,
  originalField: "original-id" | "original-slug" = "original-id"
): (originalId: string) => Promise<Model> {
  return async (originalFieldValue: string): Promise<Model> => {
    const { data } = await apiClient.get<Model>(
      `/${resource}/${originalField}/${originalFieldValue}`
    );

    return data;
  };
}

// Static pages
export type StaticPagesQueryParamas = {
  isSystem?: boolean;
} & BaseQueryParams;

export async function fetchStaticPages(
  params?: StaticPagesQueryParamas
): Promise<PaginatedResults<StaticPage>> {
  const { data } = await apiClient.get<PaginatedResults<StaticPage>>(
    "/static-pages",
    {
      params,
    }
  );

  return data;
}

export async function fetchStaticPage(slug: string): Promise<StaticPage> {
  const { data } = await apiClient.get<StaticPage>(`/static-pages/${slug}`);

  return data;
}

export async function fetchStaticPagePreview(
  slug: string,
  previewToken: string
): Promise<StaticPage> {
  const { data } = await apiClient.get<StaticPage>(`/static-pages/${slug}`, {
    params: { previewToken },
  });

  return data;
}

// Sections
export async function fetchSections(): Promise<PaginatedResults<Section>> {
  const { data } = await apiClient.get<PaginatedResults<Section>>("/sections");

  return data;
}
