import {
  createElement,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { composeClassName } from "utils/misc";

export enum CarouselItemWidth {
  Auto = "auto",
  Manual = "manual",
}

type CarouselProps = {
  itemWidth?: CarouselItemWidth;
  offset?: number;
  children?: ReactNode[];
  className?: string;
  placeholder?: JSXElementConstructor<any>;
};

export default function Carousel({
  itemWidth = CarouselItemWidth.Auto,
  offset = 0,
  children,
  className,
  placeholder,
}: CarouselProps): ReactElement {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (carouselRef.current && !firstRender) {
      const children = Array.from(carouselRef.current.children);

      const targetChild =
        offset < children.length
          ? children[offset]
          : children[children.length - 1];

      targetChild.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [carouselRef, offset]);

  useEffect(() => setFirstRender(false), []);

  return (
    <div
      className={composeClassName(
        "relative w-auto h-full -ml-3 overflow-x-scroll overflow-y-hidden -right-3 lg:right-0 lg:ml-0 snap-x snap-proximity scrollbar-hide",
        className
      )}
    >
      <div
        ref={carouselRef}
        className={composeClassName(
          "flex h-full space-x-5 w-max children:snap-center",
          itemWidth === CarouselItemWidth.Auto &&
            "children:w-60 lg:children:w-col-3"
        )}
      >
        {children?.length
          ? children
          : placeholder &&
            Array.from({ length: 12 }, (_, index) =>
              createElement(placeholder, {
                key: `carouselPlaceholderItem${index}`,
              })
            )}
      </div>
    </div>
  );
}
