import { ReactElement, SVGProps } from "react";
import { composeClassName } from "utils/misc";

export function BlankAvatar(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.70227 13.8H12.2977V12.2H7.70227V13.8ZM12.2977 13.8C13.9006 13.8 15.2 15.0994 15.2 16.7023H16.8C16.8 14.2157 14.7843 12.2 12.2977 12.2V13.8ZM7.70227 12.2C5.21574 12.2 3.2 14.2157 3.2 16.7023H4.8C4.8 15.0994 6.09939 13.8 7.70227 13.8V12.2ZM12.2 7C12.2 8.21503 11.215 9.2 10 9.2V10.8C12.0987 10.8 13.8 9.09868 13.8 7H12.2ZM10 9.2C8.78497 9.2 7.8 8.21503 7.8 7H6.2C6.2 9.09868 7.90132 10.8 10 10.8V9.2ZM7.8 7C7.8 5.78497 8.78497 4.8 10 4.8V3.2C7.90132 3.2 6.2 4.90132 6.2 7H7.8ZM10 4.8C11.215 4.8 12.2 5.78497 12.2 7H13.8C13.8 4.90132 12.0987 3.2 10 3.2V4.8ZM18.2 10C18.2 14.5287 14.5287 18.2 10 18.2V19.8C15.4124 19.8 19.8 15.4124 19.8 10H18.2ZM10 18.2C5.47126 18.2 1.8 14.5287 1.8 10H0.2C0.2 15.4124 4.58761 19.8 10 19.8V18.2ZM1.8 10C1.8 5.47126 5.47126 1.8 10 1.8V0.2C4.58761 0.2 0.2 4.58761 0.2 10H1.8ZM10 1.8C14.5287 1.8 18.2 5.47126 18.2 10H19.8C19.8 4.58761 15.4124 0.2 10 0.2V1.8Z" />
    </svg>
  );
}

export function Comment(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 1H1V13H4V18L9 13H17V1Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChevronDown(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 1L6 6L11 1"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChevronLeft(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 1L1 6L6 11"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChevronRight(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 11L6 6L1 1"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Download(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path
        d="M17 9V16C17 17.1046 16.1046 18 15 18H3C1.89543 18 1 17.1046 1 16V9M9 1L9 13M9 1L13 5M9 1L5 5"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Diamond(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute inset-y-1/2 -translate-y-2/4"
      stroke="currentColor"
      {...props}
    >
      <path
        d="M10 1C10 5.97056 14.0294 10 19 10C14.0294 10 10 14.0294 10 19C10 14.0294 5.97056 10 1 10C5.97056 10 10 5.97056 10 1Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Disc(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
        d="M20.667 26.665V20h-8v6.666m14.666-13.562V24a2.667 2.667 0 01-2.666 2.666h-16A2.667 2.667 0 016 24v-16a2.667 2.667 0 012.667-2.667h10.895c.707 0 1.386.281 1.886.781l5.104 5.105c.5.5.781 1.178.781 1.885z"
      ></path>
    </svg>
  );
}

export function Hamburger(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      stroke="#7C8894"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 1.5H17M1 6.5H17M1 11.5H17"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Grid(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="black"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 1.5H8.5V8.5H1.5V1.5Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 11.5H8.5V18.5H1.5V11.5Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 1.5H18.5V8.5H11.5V1.5Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 11.5H18.5V18.5H11.5V11.5Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function House(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.6665 9.66667L10.9998 1.5L20.3332 9.66667L20.3332 21.3333H14.4998V16.6667C14.4998 15.7384 14.1311 14.8482 13.4747 14.1918C12.8183 13.5354 11.9281 13.1667 10.9998 13.1667C10.0716 13.1667 9.18135 13.5354 8.52497 14.1918C7.86859 14.8482 7.49984 15.7384 7.49984 16.6667V21.3333H1.66651L1.6665 9.66667Z"
        stroke="#7C8894"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Lightning(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="17"
      height="23"
      viewBox="0 0 17 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6667 1.6665H4.5L1 12.1665H5.66667L3.33333 21.4998L16.1667 8.6665H9.86667L12.6667 1.6665Z"
        stroke="#7C8894"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function List(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="#7C8894"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 18.5L1.5 11.5L18.5 11.5V18.5H1.5Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 8.5L1.5 1.5L18.5 1.5V8.5L1.5 8.5Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LoginArrow(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14 4h4a2 2 0 012 2v12a2 2 0 01-2 2h-4m1-8H3m12 0l-4 4m4-4l-4-4"
      ></path>
    </svg>
  );
}

export function Magnifier(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.4343 19.0657C17.7467 19.3781 18.2533 19.3781 18.5657 19.0657C18.8781 18.7533 18.8781 18.2467 18.5657 17.9343L17.4343 19.0657ZM15.2 9C15.2 12.7003 12.2003 15.7 8.5 15.7V17.3C13.084 17.3 16.8 13.584 16.8 9H15.2ZM8.5 15.7C4.79969 15.7 1.8 12.7003 1.8 9H0.2C0.2 13.584 3.91604 17.3 8.5 17.3V15.7ZM1.8 9C1.8 5.29969 4.79969 2.3 8.5 2.3V0.7C3.91604 0.7 0.2 4.41604 0.2 9H1.8ZM8.5 2.3C12.2003 2.3 15.2 5.29969 15.2 9H16.8C16.8 4.41604 13.084 0.7 8.5 0.7V2.3ZM18.5657 17.9343L14.3781 13.7467L13.2467 14.8781L17.4343 19.0657L18.5657 17.9343Z" />
    </svg>
  );
}

export function Mail(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      fill="none"
      viewBox="0 0 20 16"
      {...props}
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.5 1.5l7.086 7.086a2 2 0 002.828 0L18.5 1.5M2 15h16a1 1 0 001-1V2a1 1 0 00-1-1H2a1 1 0 00-1 1v12a1 1 0 001 1z"
      ></path>
    </svg>
  );
}

export function Moon(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#7C8894"
      {...props}
    >
      <path
        d="M1 10.8333C6.30135 11.9118 10.1219 5.3385 6.33333 1.5C9.66714 1.58763 12.3333 4.24099 12.3333 7.5C12.3333 10.8143 9.74589 13.5 6.33333 13.5C4.18074 13.5 2.10625 12.4546 1 10.8333Z"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Plus(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99967 1.33203V14.6654M1.33301 7.9987L14.6663 7.9987"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Send(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 1L1 8L8 11M18 1L11 18L8 11M18 1L8 11"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Sun(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      xmlns="http://www.w3.org/2000/svg"
      stroke="white"
      {...props}
    >
      <path
        d="M1 7.5H2.33333M11.6667 7.5H13M7 1.5V2.83333M7 12.1667V13.5M2.33335 2.83335L3.66668 4.16668M10.3333 10.8333L11.6667 12.1667M2.33337 12.1667L3.66671 10.8333M10.3333 4.16665L11.6666 2.83332M9 7.5C9 8.60457 8.10457 9.5 7 9.5C5.89543 9.5 5 8.60457 5 7.5C5 6.39543 5.89543 5.5 7 5.5C8.10457 5.5 9 6.39543 9 7.5Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Trolley(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="white"
      {...props}
    >
      <path
        d="M16 15C14.8954 15 14 15.8954 14 17C14 18.1046 14.8954 19 16 19C17.1046 19 18 18.1046 18 17C18 15.8954 17.1046 15 16 15ZM16 15H6.36729C5.86964 15 5.44772 14.6341 5.37735 14.1414L5.07143 12M1 1H3.5L3.78571 3M3.78571 3H4H20L17 12H5.5H5.07143M3.78571 3L5.07143 12M10 17C10 18.1046 9.10457 19 8 19C6.89543 19 6 18.1046 6 17C6 15.8954 6.89543 15 8 15C9.10457 15 10 15.8954 10 17Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function UserAdd(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#fff"
        d="M17.25 18.702a.75.75 0 001.5 0h-1.5zm-12 0a.75.75 0 001.5 0h-1.5zM16.125 4.853a.75.75 0 10.75-1.298l-.75 1.298zm5.49 8.771a.75.75 0 10-1.48-.248l1.48.248zM9.702 15.75h4.596v-1.5H9.702v1.5zm4.596 0a2.952 2.952 0 012.952 2.952h1.5a4.452 4.452 0 00-4.452-4.452v1.5zm-4.596-1.5a4.452 4.452 0 00-4.452 4.452h1.5a2.952 2.952 0 012.952-2.952v-1.5zM14.25 9A2.25 2.25 0 0112 11.25v1.5A3.75 3.75 0 0015.75 9h-1.5zM12 11.25A2.25 2.25 0 019.75 9h-1.5A3.75 3.75 0 0012 12.75v-1.5zM9.75 9A2.25 2.25 0 0112 6.75v-1.5A3.75 3.75 0 008.25 9h1.5zM12 6.75A2.25 2.25 0 0114.25 9h1.5A3.75 3.75 0 0012 5.25v1.5zm0 13.5A8.25 8.25 0 013.75 12h-1.5c0 5.385 4.365 9.75 9.75 9.75v-1.5zM3.75 12A8.25 8.25 0 0112 3.75v-1.5c-5.385 0-9.75 4.365-9.75 9.75h1.5zM12 3.75c1.504 0 2.912.402 4.125 1.103l.75-1.298A9.708 9.708 0 0012 2.25v1.5zm8.136 9.626A8.252 8.252 0 0112 20.25v1.5c4.832 0 8.841-3.514 9.615-8.126l-1.48-.248z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M20 5v6m-3-3h6"
      ></path>
    </svg>
  );
}

export function User(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.31931 16.25H14.6807V14.75H9.31931V16.25ZM14.6807 16.25C16.652 16.25 18.25 17.848 18.25 19.8193H19.75C19.75 17.0196 17.4804 14.75 14.6807 14.75V16.25ZM9.31931 14.75C6.51961 14.75 4.25 17.0196 4.25 19.8193H5.75C5.75 17.848 7.34804 16.25 9.31931 16.25V14.75ZM14.75 8.5C14.75 10.0188 13.5188 11.25 12 11.25V12.75C14.3472 12.75 16.25 10.8472 16.25 8.5H14.75ZM12 11.25C10.4812 11.25 9.25 10.0188 9.25 8.5H7.75C7.75 10.8472 9.65279 12.75 12 12.75V11.25ZM9.25 8.5C9.25 6.98122 10.4812 5.75 12 5.75V4.25C9.65279 4.25 7.75 6.15279 7.75 8.5H9.25ZM12 5.75C13.5188 5.75 14.75 6.98122 14.75 8.5H16.25C16.25 6.15279 14.3472 4.25 12 4.25V5.75ZM21.75 12C21.75 17.3848 17.3848 21.75 12 21.75V23.25C18.2132 23.25 23.25 18.2132 23.25 12H21.75ZM12 21.75C6.61522 21.75 2.25 17.3848 2.25 12H0.75C0.75 18.2132 5.7868 23.25 12 23.25V21.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25V0.75C5.7868 0.75 0.75 5.7868 0.75 12H2.25ZM12 2.25C17.3848 2.25 21.75 6.61522 21.75 12H23.25C23.25 5.7868 18.2132 0.75 12 0.75V2.25Z"
        fill="#A71C16"
      />
    </svg>
  );
}

export function ArrowRight(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 5L1 5M11 5L7 9M11 5L7 1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Eye(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 6.6C1 6.6 3.90909 1 9 1C14.0909 1 17 6.6 17 6.6C17 6.6 14.0909 12.2 9 12.2C3.90909 12.2 1 6.6 1 6.6Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9C10.3255 9 11.4 7.92548 11.4 6.6C11.4 5.27452 10.3255 4.2 9 4.2C7.67452 4.2 6.6 5.27452 6.6 6.6C6.6 7.92548 7.67452 9 9 9Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Close(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 1L1 15M1.00001 1L15 15"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Facebook(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M20.475 7.984h2.191V4.17C22.289 4.117 20.989 4 19.476 4c-3.16 0-5.324 1.987-5.324 5.64V13h-3.486v4.266h3.486V28h4.273V17.266h3.346L22.302 13h-3.877v-2.94c0-1.232.332-2.076 2.05-2.076z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function Instagram(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M12 5.442c2.136 0 2.39.008 3.233.047 2.168.098 3.181 1.127 3.28 3.279.038.843.046 1.097.046 3.233s-.008 2.389-.046 3.232c-.1 2.15-1.11 3.181-3.28 3.28-.844.038-1.096.046-3.233.046-2.136 0-2.39-.008-3.233-.046-2.173-.1-3.18-1.133-3.279-3.28-.039-.844-.047-1.096-.047-3.233 0-2.136.009-2.389.047-3.233.1-2.151 1.11-3.18 3.28-3.279.843-.038 1.096-.046 3.232-.046zM12 4c-2.173 0-2.445.01-3.298.048-2.905.133-4.52 1.745-4.653 4.653C4.009 9.555 4 9.827 4 12s.01 2.445.048 3.299c.133 2.905 1.745 4.52 4.653 4.653.854.039 1.126.048 3.299.048s2.445-.01 3.299-.048c2.902-.133 4.521-1.745 4.652-4.653.04-.854.049-1.126.049-3.299s-.01-2.445-.048-3.298c-.13-2.903-1.745-4.52-4.653-4.653C14.445 4.009 14.173 4 12 4zm0 3.892a4.108 4.108 0 100 8.216 4.108 4.108 0 000-8.216zm0 6.775a2.666 2.666 0 110-5.333 2.666 2.666 0 010 5.333zm4.27-7.897a.96.96 0 100 1.92.96.96 0 000-1.92z"></path>
    </svg>
  );
}

export function Twitter(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M26.592 11.652c.017.22.017.438.017.657 0 6.671-5.5 14.359-15.55 14.359-3.097 0-5.973-.828-8.393-2.266.44.047.863.063 1.32.063 2.555 0 4.907-.797 6.785-2.156-2.403-.047-4.416-1.5-5.11-3.5.338.046.677.078 1.032.078.49 0 .982-.063 1.438-.172-2.504-.469-4.382-2.5-4.382-4.953v-.063c.727.375 1.574.61 2.47.64-1.472-.905-2.436-2.452-2.436-4.202 0-.938.27-1.797.744-2.547 2.69 3.062 6.735 5.062 11.27 5.281a5.278 5.278 0 01-.136-1.156c0-2.781 2.437-5.047 5.465-5.047 1.574 0 2.995.61 3.993 1.594 1.236-.219 2.42-.64 3.47-1.219-.407 1.172-1.27 2.156-2.403 2.781 1.1-.11 2.165-.39 3.147-.781-.745 1-1.675 1.89-2.741 2.61z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function Discord(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.773 14.2589C14.773 14.2589 14.156 13.522 13.6419 12.8878C15.887 12.2538 16.7439 10.8656 16.7439 10.8656C16.0412 11.3284 15.3728 11.654 14.773 11.8768C13.9161 12.2367 13.0934 12.4594 12.288 12.6137C10.6428 12.9222 9.13467 12.8365 7.84923 12.5966C6.87241 12.408 6.03262 12.151 5.32995 11.8596C4.93579 11.7054 4.50738 11.5169 4.07887 11.277C4.05318 11.2598 4.02746 11.247 4.00174 11.2341C3.97603 11.2213 3.95031 11.2085 3.92462 11.1913C3.91149 11.1848 3.90085 11.1782 3.89179 11.1725C3.87722 11.1635 3.86666 11.157 3.8561 11.157C3.54759 10.9856 3.37621 10.8657 3.37621 10.8657C3.37621 10.8657 4.19877 12.2196 6.37538 12.8708C5.86123 13.5221 5.22708 14.2761 5.22708 14.2761C1.43959 14.1561 0 11.6882 0 11.6882C0 6.22127 2.4678 1.7825 2.4678 1.7825C4.93569 -0.0512403 7.26646 0.00014472 7.26646 0.00014472L7.43785 0.205786C4.35303 1.07984 2.94769 2.43379 2.94769 2.43379C2.94769 2.43379 3.32472 2.22814 3.95887 1.95389C5.79272 1.14835 7.24933 0.942709 7.84923 0.874196L7.88784 0.867675C7.97424 0.852975 8.05086 0.839939 8.14051 0.839939C9.18595 0.702811 10.3685 0.668555 11.6024 0.805581C13.2305 0.994094 14.9786 1.47399 16.7608 2.43368C16.7608 2.43368 15.4069 1.14835 12.4935 0.274299L12.7335 0.00014472C12.7335 0.00014472 15.0814 -0.0512403 17.5321 1.7825C17.5321 1.7825 20 6.22127 20 11.6882C20 11.6873 19.996 11.6933 19.9879 11.7055C19.8421 11.9241 18.3614 14.1452 14.773 14.2589ZM6.79342 6.32266C5.81659 6.32266 5.04531 7.16245 5.04531 8.20789C5.04531 9.25332 5.83372 10.0931 6.79342 10.0931C7.77024 10.0931 8.54152 9.25332 8.54152 8.20789C8.55865 7.16245 7.77034 6.32266 6.79342 6.32266ZM11.3006 8.20789C11.3006 7.16245 12.0719 6.32266 13.0487 6.32266C14.0084 6.32266 14.7968 7.16245 14.7968 8.20789C14.7968 9.25332 14.0255 10.0931 13.0487 10.0931C12.089 10.0931 11.3006 9.25332 11.3006 8.20789Z"
      />
    </svg>
  );
}

export function PlayStation(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="30"
      height="23"
      viewBox="0 0 30 23"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4055 17.6617C28.8325 18.3846 27.4286 18.9003 27.4286 18.9003L16.9855 22.6514V19.885L24.671 17.1467C25.5431 16.8342 25.677 16.3924 24.9681 16.1606C24.2605 15.928 22.9793 15.9947 22.1065 16.3085L16.9855 18.1121V15.2411L17.2807 15.1412C17.2807 15.1412 18.7605 14.6175 20.8412 14.387C22.922 14.1578 25.4698 14.4183 27.47 15.1765C29.7239 15.8887 29.9778 16.9388 29.4055 17.6617ZM17.9796 12.9511V5.87669C17.9796 5.04585 17.8264 4.28097 17.0468 4.06443C16.4498 3.87321 16.0794 4.42755 16.0794 5.25772V22.9739L11.3016 21.4574V0.333984C13.333 0.711094 16.2926 1.60256 17.8837 2.13891C21.9299 3.52808 23.3018 5.25706 23.3018 9.15274C23.3018 12.9498 20.9578 14.389 17.9796 12.9511ZM2.53479 19.5959C0.220829 18.9443 -0.164275 17.5864 0.890431 16.8042C1.86518 16.082 3.52287 15.5383 3.52287 15.5383L10.3735 13.1024V15.8794L5.44372 17.6437C4.57291 17.9562 4.43899 18.3986 5.14657 18.6304C5.85481 18.8623 7.13672 18.7963 8.00886 18.4832L10.3735 17.625V20.1096C10.2235 20.1362 10.0563 20.1629 9.90174 20.1888C7.53648 20.5753 5.01731 20.414 2.53479 19.5959Z"
      />
    </svg>
  );
}

export function Twitch(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.209 0L0 3.08925V15.7162H4.29825V18H6.71625L9 15.7162H12.4928L17.1945 11.0145V0H1.209V0ZM15.582 10.209L12.8955 12.8955H8.59725L6.3135 15.1793V12.8955H2.6865V1.61175H15.582V10.209ZM12.8955 4.70175V9.39825H11.2838V4.70175H12.8955ZM8.59725 4.70175V9.39825H6.9855V4.70175H8.59725Z"
      />
    </svg>
  );
}

export function YouTube(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16.3458 0.153438C13.3425 -0.0515623 6.65333 -0.050729 3.65417 0.153438C0.406667 0.375104 0.0241667 2.33677 0 7.5001C0.0241667 12.6543 0.403333 14.6243 3.65417 14.8468C6.65417 15.0509 13.3425 15.0518 16.3458 14.8468C19.5933 14.6251 19.9758 12.6634 20 7.5001C19.9758 2.34594 19.5967 0.375938 16.3458 0.153438ZM7.5 10.8334V4.16677L14.1667 7.49427L7.5 10.8334Z" />
    </svg>
  );
}

export function Xbox(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.7974 23.9506C8.94972 23.7737 7.07906 23.1101 5.47209 22.0617C4.1255 21.1831 3.82141 20.8219 3.82141 20.1012C3.82141 18.6534 5.41328 16.1176 8.13686 13.2269C9.68367 11.5851 11.8383 9.66075 12.0713 9.71286C12.5242 9.81415 16.1457 13.3467 17.5014 15.0096C19.6453 17.6392 20.6308 19.7922 20.1301 20.7521C19.7495 21.4818 17.3878 22.908 15.6527 23.4559C14.2226 23.9074 12.3445 24.0988 10.7974 23.9506ZM2.00268 18.5959C0.883691 16.8792 0.318341 15.1892 0.0453864 12.7448C-0.0447439 11.9376 -0.0124459 11.476 0.250049 9.81928C0.577211 7.75446 1.75309 5.36572 3.16602 3.89565C3.7678 3.26954 3.82154 3.25428 4.55507 3.50139C5.44585 3.80147 6.39713 4.45846 7.87244 5.79248L8.73321 6.57082L8.26316 7.14827C6.0812 9.82883 3.77783 13.6284 2.90976 15.9791C2.43785 17.2571 2.2475 18.5399 2.45056 19.074C2.58765 19.4345 2.46173 19.3001 2.00268 18.5959ZM21.6444 18.8879C21.7549 18.3482 21.6151 17.3572 21.2874 16.3576C20.5778 14.1928 18.2058 10.1655 16.0276 7.42742L15.3419 6.56546L16.0838 5.88431C17.0524 4.99493 17.7249 4.46238 18.4505 4.01013C19.0231 3.65325 19.8413 3.33732 20.193 3.33732C20.4099 3.33732 21.1733 4.12957 21.7895 4.99417C22.744 6.33325 23.4462 7.95651 23.8019 9.64634C24.0318 10.7382 24.051 13.0753 23.839 14.1645C23.665 15.0583 23.2977 16.2178 22.9393 17.0041C22.6708 17.5933 22.003 18.7376 21.7104 19.11C21.5599 19.3014 21.5598 19.301 21.6444 18.8879H21.6444ZM10.9952 2.92854C9.99023 2.41819 8.43984 1.87035 7.58339 1.72296C7.28315 1.67129 6.77097 1.64248 6.44522 1.65893C5.73859 1.69461 5.77016 1.65766 6.90373 1.1221C7.84617 0.676842 8.63229 0.415012 9.69946 0.190936C10.8999 -0.0611325 13.1564 -0.0640907 14.3378 0.184856C15.6138 0.453739 17.1163 1.01288 17.9629 1.5339L18.2146 1.68875L17.6372 1.6596C16.49 1.60167 14.818 2.06516 13.0229 2.93876C12.4814 3.20226 12.0104 3.4127 11.9761 3.40642C11.9418 3.40014 11.5004 3.18509 10.9952 2.92854V2.92854Z" />
    </svg>
  );
}

export function Steam(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 232.85 233"
      fill="currentColor"
      {...props}
    >
      <g id="Warstwa_2" data-name="Warstwa 2">
        <g id="Warstwa_1-2" data-name="Warstwa 1">
          <path d="M4.74,150A116.56,116.56,0,1,0,.1,108.76C7.64,121.42,10.58,129.25,4.74,150Z" />
          <path
            d="M110.35,87.32c0,.2,0,.39,0,.58L81.85,129.31a31.9,31.9,0,0,0-13.64,2.41,31.53,31.53,0,0,0-5.46,2.88L.15,108.83s-1.45,23.83,4.59,41.59L49,168.68a32.3,32.3,0,0,0,63.82-7.89l40.79-29.15,1,0a44.33,44.33,0,1,0-44.25-44.34Zm-6.84,83.92a24.86,24.86,0,0,1-45.43,1.21l14.41,6A18.37,18.37,0,0,0,86.6,144.49l-14.89-6.17a24.93,24.93,0,0,1,31.8,32.92m51.09-54.38a29.54,29.54,0,1,1,29.49-29.54,29.55,29.55,0,0,1-29.49,29.54M132.51,87.28a22.15,22.15,0,1,1,22.14,22.18A22.17,22.17,0,0,1,132.51,87.28Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export function Envelope(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
        d="M4.667 7.335l9.447 9.447a2.667 2.667 0 003.772 0l9.447-9.447m-22 18h21.334c.736 0 1.333-.597 1.333-1.334v-16c0-.736-.597-1.333-1.333-1.333H5.333C4.597 6.668 4 7.265 4 8.001v16c0 .737.597 1.334 1.333 1.334z"
      ></path>
    </svg>
  );
}

export function Frame(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.6231 9.33206V1.33208L14.6005 1.33208M1.22949 14.6654L1.22949 22.6654H9.25212M9.25212 1.33203L1.22954 1.33203L1.22954 9.33206M14.6005 22.6654H22.6231V14.6654"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ThumbsUp(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 6.50005H1V14H4M4 6.50005V14M4 6.50005L7.89668 1.95392C8.26644 1.52254 8.84818 1.33709 9.39937 1.47489L9.43504 1.48381C10.4414 1.73539 10.8947 2.90799 10.3193 3.77107L8.5 6.50005H11.9203C12.8669 6.50005 13.5768 7.36603 13.3912 8.29422L12.4912 12.7942C12.3509 13.4954 11.7353 14 11.0203 14H4"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Info(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      stroke="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.2 15C9.2 15.4418 9.55817 15.8 10 15.8C10.4418 15.8 10.8 15.4418 10.8 15H9.2ZM10.8 8C10.8 7.55817 10.4418 7.2 10 7.2C9.55817 7.2 9.2 7.55817 9.2 8H10.8ZM9.2 5.00996C9.2 5.45179 9.55817 5.80996 10 5.80996C10.4418 5.80996 10.8 5.45179 10.8 5.00996H9.2ZM10.8 4.99997C10.8 4.55814 10.4418 4.19997 10 4.19997C9.55817 4.19997 9.2 4.55814 9.2 4.99997H10.8ZM10.8 15L10.8 8H9.2L9.2 15H10.8ZM10.8 5.00996V4.99997H9.2V5.00996H10.8ZM18.2 10C18.2 14.5287 14.5287 18.2 10 18.2V19.8C15.4124 19.8 19.8 15.4124 19.8 10H18.2ZM10 18.2C5.47126 18.2 1.8 14.5287 1.8 10H0.2C0.2 15.4124 4.58761 19.8 10 19.8V18.2ZM1.8 10C1.8 5.47126 5.47126 1.8 10 1.8V0.2C4.58761 0.2 0.2 4.58761 0.2 10H1.8ZM10 1.8C14.5287 1.8 18.2 5.47126 18.2 10H19.8C19.8 4.58761 15.4124 0.2 10 0.2V1.8Z" />
    </svg>
  );
}

export function Boxes(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5 12H17C18.1046 12 19 11.1046 19 10V3C19 1.89543 18.1046 1 17 1H10C8.89543 1 8 1.89543 8 3V4.5M3 19H10C11.1046 19 12 18.1046 12 17V10C12 8.89543 11.1046 8 10 8H3C1.89543 8 1 8.89543 1 10V17C1 18.1046 1.89543 19 3 19Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Logout(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
        d="M11.667 3.334H15c.92 0 1.667.746 1.667 1.667v10c0 .92-.747 1.666-1.667 1.666h-3.333M2.5 10.001h10m-10 0l3.333-3.334M2.5 10.001l3.333 3.333"
      ></path>
    </svg>
  );
}

export function Play(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
        fill="black"
        fillOpacity="0.6"
      />
      <path
        d="M21.333 16V48L47.9997 32L21.333 16Z"
        stroke="white"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PlayCompact(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      stroke="currentColor"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 1V13L11 7L1 1Z" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  );
}

export function Check(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.0001 1L4.00004 8.00002L1 5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CrossCircle(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
      className={composeClassName(props.className, "hover:opacity-80")}
    >
      <path d="M15.53 9.53a.75.75 0 10-1.06-1.06l1.06 1.06zm-7.06 4.94a.75.75 0 101.06 1.06l-1.06-1.06zm1.06-6a.75.75 0 00-1.06 1.06l1.06-1.06zm4.94 7.06a.75.75 0 001.06-1.06l-1.06 1.06zm0-7.06l-6 6 1.06 1.06 6-6-1.06-1.06zm-6 1.06l6 6 1.06-1.06-6-6-1.06 1.06zM20.25 12A8.25 8.25 0 0112 20.25v1.5c5.385 0 9.75-4.365 9.75-9.75h-1.5zM12 20.25A8.25 8.25 0 013.75 12h-1.5c0 5.385 4.365 9.75 9.75 9.75v-1.5zM3.75 12A8.25 8.25 0 0112 3.75v-1.5c-5.385 0-9.75 4.365-9.75 9.75h1.5zM12 3.75A8.25 8.25 0 0120.25 12h1.5c0-5.385-4.365-9.75-9.75-9.75v1.5z" />
    </svg>
  );
}

export function Upload(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7 9V8a5 5 0 0110 0v1a4 4 0 012 7.465M7 9a4 4 0 00-2 7.465M7 9c.433 0 .85.069 1.24.196M12 11v10m0-10l4 4m-4-4l-4 4"
      ></path>
    </svg>
  );
}

export function Trash(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18 6v12a2 2 0 01-2 2H8a2 2 0 01-2-2V6m9 0V5a2 2 0 00-2-2h-2a2 2 0 00-2 2v1M4 6h16"
      ></path>
    </svg>
  );
}

export function Fullscreen(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
        d="M16.665 8.334v-5h-5m-8.333 8.333v5h5m0-13.333h-5v5m8.333 8.333h5v-5"
      ></path>
    </svg>
  );
}

export function Settings(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        strokeWidth="1.6"
        d="M8.333 3.333c0-.46.373-.833.834-.833h1.666c.46 0 .834.373.834.833v.474c0 .357.239.667.568.803.33.137.713.085.965-.167l.336-.336a.833.833 0 011.178 0l1.178 1.179a.833.833 0 010 1.178l-.335.336c-.252.252-.304.635-.167.965.136.33.446.568.803.568h.474c.46 0 .833.373.833.834v1.666c0 .46-.373.834-.833.834h-.474c-.357 0-.667.239-.803.568-.137.33-.085.713.167.965l.335.336a.833.833 0 010 1.178l-1.178 1.178a.833.833 0 01-1.178 0l-.336-.335c-.252-.252-.635-.304-.965-.167-.33.136-.568.446-.568.803v.474c0 .46-.373.833-.834.833H9.167a.833.833 0 01-.834-.833v-.474c0-.357-.239-.667-.568-.803-.33-.137-.713-.085-.965.167l-.336.335a.833.833 0 01-1.178 0l-1.179-1.178a.833.833 0 010-1.178l.336-.336c.252-.252.304-.635.167-.965-.136-.33-.446-.568-.803-.568h-.474a.833.833 0 01-.833-.834V9.167c0-.46.373-.834.833-.834h.474c.357 0 .667-.239.803-.568.137-.33.085-.713-.167-.965l-.336-.336a.833.833 0 010-1.178l1.179-1.179a.833.833 0 011.178 0l.336.336c.252.252.635.304.965.167.33-.136.568-.446.568-.803v-.474z"
      ></path>
      <path
        strokeWidth="1.6"
        d="M11.667 10a1.667 1.667 0 11-3.334 0 1.667 1.667 0 013.334 0z"
      ></path>
    </svg>
  );
}

export function CheckCircle(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M17.53 9.53a.75.75 0 00-1.06-1.06l1.06 1.06zM10 16l-.53.53a.75.75 0 001.06 0L10 16zm-2.47-3.53a.75.75 0 00-1.06 1.06l1.06-1.06zm8.94-4l-7 7 1.06 1.06 7-7-1.06-1.06zm-10 5.06l3 3 1.06-1.06-3-3-1.06 1.06zM20.25 12A8.25 8.25 0 0112 20.25v1.5c5.385 0 9.75-4.365 9.75-9.75h-1.5zM12 20.25A8.25 8.25 0 013.75 12h-1.5c0 5.385 4.365 9.75 9.75 9.75v-1.5zM3.75 12A8.25 8.25 0 0112 3.75v-1.5c-5.385 0-9.75 4.365-9.75 9.75h1.5zM12 3.75A8.25 8.25 0 0120.25 12h1.5c0-5.385-4.365-9.75-9.75-9.75v1.5z"></path>
    </svg>
  );
}

export function CheckSmall(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="11"
      fill="none"
      viewBox="0 0 14 11"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.4"
        d="M12 2L5 9 2 6"
      ></path>
    </svg>
  );
}

export function Edit(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M4 16l-.53-.53-.22.22V16H4zM17 3l.53-.53a.75.75 0 00-1.06 0L17 3zm4 4l.53.53a.75.75 0 000-1.06L21 7zM8 20v.75h.31l.22-.22L8 20zm-4 0h-.75c0 .414.336.75.75.75V20zm.53-3.47l13-13-1.06-1.06-13 13 1.06 1.06zm11.94-13l4 4 1.06-1.06-4-4-1.06 1.06zm4 2.94l-13 13 1.06 1.06 13-13-1.06-1.06zM8 19.25H4v1.5h4v-1.5zM4.75 20v-4h-1.5v4h1.5zm8.72-13.47l4 4 1.06-1.06-4-4-1.06 1.06z"></path>
    </svg>
  );
}

export function Calendar(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 3v4M9 3v4m-5 4h16m0 0v8a2 2 0 01-2 2H6a2 2 0 01-2-2V7a2 2 0 012-2h12a2 2 0 012 2v4z"
      ></path>
    </svg>
  );
}

export function ChevronUpSmallCircle(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M16.47 14.53a.75.75 0 101.06-1.06l-1.06 1.06zM12 9l.53-.53a.75.75 0 00-1.06 0L12 9zm-5.53 4.47a.75.75 0 101.06 1.06l-1.06-1.06zm11.06 0l-5-5-1.06 1.06 5 5 1.06-1.06zm-6.06-5l-5 5 1.06 1.06 5-5-1.06-1.06zM20.25 12A8.25 8.25 0 0112 20.25v1.5c5.385 0 9.75-4.365 9.75-9.75h-1.5zM12 20.25A8.25 8.25 0 013.75 12h-1.5c0 5.385 4.365 9.75 9.75 9.75v-1.5zM3.75 12A8.25 8.25 0 0112 3.75v-1.5c-5.385 0-9.75 4.365-9.75 9.75h1.5zM12 3.75A8.25 8.25 0 0120.25 12h1.5c0-5.385-4.365-9.75-9.75-9.75v1.5z"></path>
    </svg>
  );
}

export function ChevronDownSmallCircle(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M7.53 9.47a.75.75 0 00-1.06 1.06l1.06-1.06zM12 15l-.53.53a.75.75 0 001.06 0L12 15zm5.53-4.47a.75.75 0 10-1.06-1.06l1.06 1.06zm-11.06 0l5 5 1.06-1.06-5-5-1.06 1.06zm6.06 5l5-5-1.06-1.06-5 5 1.06 1.06zM20.25 12A8.25 8.25 0 0112 20.25v1.5c5.385 0 9.75-4.365 9.75-9.75h-1.5zM12 20.25A8.25 8.25 0 013.75 12h-1.5c0 5.385 4.365 9.75 9.75 9.75v-1.5zM3.75 12A8.25 8.25 0 0112 3.75v-1.5c-5.385 0-9.75 4.365-9.75 9.75h1.5zM12 3.75A8.25 8.25 0 0120.25 12h1.5c0-5.385-4.365-9.75-9.75-9.75v1.5z"></path>
    </svg>
  );
}

export function HappyFace(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="122"
      height="122"
      fill="none"
      viewBox="0 0 122 122"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
        d="M34.333 74.333s10 13.334 26.667 13.334 26.667-13.334 26.667-13.334M41 41l.067 10M81 51l.067-10M121 61c0 33.137-26.863 60-60 60S1 94.137 1 61 27.863 1 61 1s60 26.863 60 60z"
      ></path>
    </svg>
  );
}

export function CrossSmall(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16 8l-8 8m0-8l8 8"
      ></path>
    </svg>
  );
}

export function SadFace(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="244"
      height="244"
      viewBox="0 0 244 244"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path
        d="M175.333 175.333C175.333 175.333 155.333 148.667 122 148.667C88.6667 148.667 68.6667 175.333 68.6667 175.333M82.1333 110V82M162.133 110V82M242 122C242 188.274 188.274 242 122 242C55.7258 242 2 188.274 2 122C2 55.7258 55.7258 2 122 2C188.274 2 242 55.7258 242 122Z"
        strokeWidth="3.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Star(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 3a9 9 0 009 9 9 9 0 00-9 9 9 9 0 00-9-9 9 9 0 009-9z"
      ></path>
    </svg>
  );
}

export function CurvesGroup(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="256"
      height="200"
      fill="none"
      viewBox="0 0 256 200"
      {...props}
    >
      <g
        stroke="#820600"
        strokeMiterlimit="10"
        strokeWidth="0.92"
        clipPath="url(#clip0_1058_90322)"
      >
        <path d="M126.233 113.397c68.404-87.482 91.519-183.645 51.628-214.785C137.97-132.529 50.179-86.855-18.225.627-86.63 88.108-109.745 184.27-69.854 215.41c39.891 31.141 127.682-14.533 196.087-102.014z"></path>
        <path d="M111.684 128.179c86.347-69.865 130.519-158.367 98.662-197.676C178.488-108.805 82.664-84.035-3.682-14.17c-86.347 69.865-130.519 158.367-98.662 197.675 31.858 39.309 127.682 14.538 214.028-55.326z"></path>
        <path d="M94.173 139.325C194.035 90.659 257 14.354 234.809-31.108 212.618-76.57 113.673-73.972 13.811-25.305-86.052 23.36-149.017 99.666-126.826 145.128c22.191 45.462 121.136 42.864 220.999-5.803z"></path>
        <path d="M74.617 146.251c108.26-24.973 186.79-85.17 175.403-134.455-11.388-49.284-108.381-68.992-216.64-44.019C-74.88-7.25-153.412 52.947-142.025 102.232c11.388 49.284 108.381 68.992 216.641 44.019z"></path>
        <path d="M53.991 148.584C165.1 148.584 255.17 107.581 255.17 57c0-50.58-90.07-91.584-201.178-91.584-111.107 0-201.178 41.003-201.178 91.584s90.07 91.584 201.178 91.584z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1058_90322">
          <path
            fill="#fff"
            d="M0 0H404V338H0z"
            transform="translate(-148 -112)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export function PlusRound(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="22"
      fill="currentColor"
      viewBox="0 0 19 22"
      {...props}
    >
      <path d="M10.25 7a.75.75 0 00-1.5 0h1.5zm-1.5 8a.75.75 0 001.5 0h-1.5zM5.5 10.25a.75.75 0 000 1.5v-1.5zm8 1.5a.75.75 0 000-1.5v1.5zM8.75 7v8h1.5V7h-1.5zM5.5 11.75h8v-1.5h-8v1.5zm0-8h8v-1.5h-8v1.5zM16.75 7v8h1.5V7h-1.5zM13.5 18.25h-8v1.5h8v-1.5zM2.25 15V7H.75v8h1.5zm3.25 3.25A3.25 3.25 0 012.25 15H.75a4.75 4.75 0 004.75 4.75v-1.5zM16.75 15a3.25 3.25 0 01-3.25 3.25v1.5A4.75 4.75 0 0018.25 15h-1.5zM13.5 3.75A3.25 3.25 0 0116.75 7h1.5a4.75 4.75 0 00-4.75-4.75v1.5zm-8-1.5A4.75 4.75 0 00.75 7h1.5A3.25 3.25 0 015.5 3.75v-1.5z"></path>
    </svg>
  );
}

export function MinusRound(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="22"
      fill="currentColor"
      viewBox="0 0 19 22"
      {...props}
    >
      <path d="M5.5 10.25a.75.75 0 000 1.5v-1.5zm8 1.5a.75.75 0 000-1.5v1.5zm-8 0h8v-1.5h-8v1.5zm0-8h8v-1.5h-8v1.5zM16.75 7v8h1.5V7h-1.5zM13.5 18.25h-8v1.5h8v-1.5zM2.25 15V7H.75v8h1.5zm3.25 3.25A3.25 3.25 0 012.25 15H.75a4.75 4.75 0 004.75 4.75v-1.5zM16.75 15a3.25 3.25 0 01-3.25 3.25v1.5A4.75 4.75 0 0018.25 15h-1.5zM13.5 3.75A3.25 3.25 0 0116.75 7h1.5a4.75 4.75 0 00-4.75-4.75v1.5zm-8-1.5A4.75 4.75 0 00.75 7h1.5A3.25 3.25 0 015.5 3.75v-1.5z"></path>
    </svg>
  );
}

export function StraightFace(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 8L7.01 7M13 8L13.01 7M6 13H14M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function HappyFace2(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 12C6 12 7.5 14 10 14C12.5 14 14 12 14 12M7 8L7.01 7M13 8L13.01 7M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Bolt(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21.333 5.333H12l-4 12h5.333L10.667 28l14.666-14.666h-7.2l3.2-8z"
      ></path>
    </svg>
  );
}

export function Home2(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5 10l7-7 7 7v10H5V10z"
      ></path>
    </svg>
  );
}

export function User1(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2M16 7a4 4 0 11-8 0 4 4 0 018 0z"
      ></path>
    </svg>
  );
}

export function FeaturedStar(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="26"
        height="26"
        rx="4"
        fill="url(#paint0_linear_3334_169332)"
      />
      <path
        d="M13 6.25C13 9.97792 16.0221 13 19.75 13C16.0221 13 13 16.0221 13 19.75C13 16.0221 9.97792 13 6.25 13C9.97792 13 13 9.97792 13 6.25Z"
        stroke="#F2F5F7"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3334_169332"
          x1="0"
          y1="0"
          x2="26"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9F19" />
          <stop offset="0.50689" stopColor="#FFBD00" />
          <stop offset="1" stopColor="#FADF62" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function Tooltip(
  props: SVGProps<SVGSVGElement>
): ReactElement<SVGSVGElement> {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9 0C13.0164 0 13.9324 0.876102 13.9964 1.97681L14 2.1V9.1C14 10.2164 13.1239 11.1324 12.0232 11.1964L11.9 11.2H9.45L7.56 13.72C7.4277 13.8964 7.2205 14 7 14C6.80706 14 6.62431 13.9207 6.493 13.7827L6.44 13.72L4.55 11.2H2.1C0.98355 11.2 0.067575 10.3239 0.00357226 9.22319L0 9.1V2.1C0 0.98355 0.876102 0.067575 1.97681 0.00357226L2.1 0H11.9ZM11.9 1.4H2.1C1.74185 1.4 1.44526 1.671 1.40472 2.01853L1.4 2.1V9.1C1.4 9.45815 1.671 9.75473 2.01853 9.79528L2.1 9.8H4.9C5.09294 9.8 5.27569 9.87932 5.407 10.0173L5.46 10.08L7 12.1331L8.54 10.08C8.65576 9.92565 8.82887 9.82704 9.01806 9.8048L9.1 9.8H11.9C12.2582 9.8 12.5547 9.529 12.5953 9.18147L12.6 9.1V2.1C12.6 1.74185 12.329 1.44526 11.9815 1.40472L11.9 1.4ZM7 2.8C7.3588 2.8 7.65483 3.0704 7.69529 3.4184L7.7 3.5V4.9H9.1C9.4588 4.9 9.75483 5.1704 9.79529 5.5184L9.8 5.6C9.8 5.9588 9.5296 6.25483 9.1816 6.29529L9.1 6.3H7.7V7.7C7.7 8.0588 7.4296 8.35483 7.0816 8.39529L7 8.4C6.6412 8.4 6.34517 8.1296 6.30471 7.7816L6.3 7.7V6.3H4.9C4.5412 6.3 4.24517 6.0296 4.20471 5.6816L4.2 5.6C4.2 5.2412 4.4704 4.94517 4.8184 4.90471L4.9 4.9H6.3V3.5C6.3 3.1412 6.5704 2.84517 6.9184 2.80471L7 2.8Z"
      />
    </svg>
  );
}
