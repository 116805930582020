import {
  ArticleCategoriesQueryParams,
  ArticlesQueryParams,
  CommentsQueryParams,
  EditorsQueryParams,
  GamesQueryParams,
  MagazinesQueryParams,
  SearchQueryParams,
  StaticPagesQueryParamas,
  TagsQueryParams,
  UsersQueryParams,
  VideosQueryParams,
} from "utils/api";

function createDefaultQueryKeysFactory<IdFieldType, FiltersType>(
  mainKey: readonly string[]
) {
  const queryKeysFactory = {
    all: [...mainKey] as const,
    filtered: (filters: FiltersType) =>
      [...queryKeysFactory.all, "filtered", { filters }] as const,
    one: (id: IdFieldType) =>
      [...queryKeysFactory.all, "one", ...[id || []]] as const,
    oneDetailed: (id: IdFieldType, detail: string) =>
      [
        ...queryKeysFactory.all,
        "one",
        ...[id || []],
        "detailed",
        detail,
      ] as const,
    oneFiltered: (id: IdFieldType, filters: FiltersType) =>
      [
        ...queryKeysFactory.all,
        "one",
        ...[id || []],
        "filtered",
        { filters },
      ] as const,
  };

  return queryKeysFactory;
}

export const articleCategoriesKeys = createDefaultQueryKeysFactory<
  string,
  ArticleCategoriesQueryParams
>(["articleCategories"]);

export const articlesKeys = createDefaultQueryKeysFactory<
  string,
  ArticlesQueryParams
>(["articles"]);

export const gamesKeys = createDefaultQueryKeysFactory<
  string,
  GamesQueryParams
>(["games"]);

export const gameMarksKeys = createDefaultQueryKeysFactory<
  string,
  GamesQueryParams
>(["gameMarks"]);

export const editorsKeys = createDefaultQueryKeysFactory<
  string,
  EditorsQueryParams
>(["editors"]);

export const usersKeys = createDefaultQueryKeysFactory<
  string,
  UsersQueryParams
>(["users"]);

export const tagsKeys = createDefaultQueryKeysFactory<string, TagsQueryParams>([
  "tags",
]);

export const commentsKeys = {
  all: ["comments"] as const,
  for: (
    commentableType: string,
    commentableIdentifier: string,
    filters?: CommentsQueryParams
  ) =>
    [
      ...commentsKeys.all,
      "for",
      commentableType,
      commentableIdentifier,
      ...(filters ? [{ filters }] : []),
    ] as const,
  one: (id: number) => [...commentsKeys.all, "one", ...[id || []]] as const,
};

export const magazinesKeys = createDefaultQueryKeysFactory<
  string,
  MagazinesQueryParams
>(["magazines"]);

export const videosKeys = createDefaultQueryKeysFactory<
  string,
  VideosQueryParams
>(["videos"]);

export const searchKeys = createDefaultQueryKeysFactory<
  string,
  SearchQueryParams
>(["search"]);

export const staticPagesKeys = createDefaultQueryKeysFactory<
  string,
  StaticPagesQueryParamas
>(["static-pages"]);

export const sectionsKeys = createDefaultQueryKeysFactory<
  string,
  StaticPagesQueryParamas
>(["sections"]);
