import {
  Discord,
  Facebook,
  Instagram,
  Twitch,
  Twitter,
  YouTube,
} from "components/Icons";
import type { ReactNode } from "react";

export const AUTH_TOKEN = "auth-token";

export type NavItem = {
  label: string;
  url: string;
  children?: NavItem[];
  categoryId?: number | string;
  icon?: ReactNode;
  openLinksInNewTab?: boolean;
};

export const NAV: { [key: string]: NavItem[] } = {
  MAIN: [
    {
      label: "Newsy",
      url: "/newsy",
    },
    {
      label: "Recenzje",
      url: "/recenzje",
    },
    {
      label: "Teksty",
      url: "/teksty",
      categoryId: "in:4,5,6,7",
      children: [
        {
          label: "Publicystyka",
          url: "/publicystyka",
        },
        {
          label: "Zapowiedzi",
          url: "/zapowiedzi",
        },
        {
          label: "Już graliśmy",
          url: "/juz-gralismy",
        },
        // {
        //   label: "Poradniki",
        //   url: "/poradniki",
        // },
      ],
    },
    {
      label: "Kultura",
      url: "/kultura",
    },
    {
      label: "Wideo",
      url: "/wideo",
    },
    {
      label: "Czasopismo",
      url: "/czasopismo",
    },
    {
      label: "Technologie",
      url: "/technologie",
      categoryId: "in:10,11",
      children: [
        {
          label: "Artykuły",
          url: "/artykuly",
        },
        {
          label: "Testy",
          url: "/testy",
        },
      ],
    },
    {
      label: "Baza gier",
      url: "/gry",
    },
    // {
    //   label: "Okazje",
    //   url: "/okazje",
    // },
    // {
    //   label: "Action Mag",
    //   url: "/action-mag",
    // },
    {
      label: "Na luzie",
      url: "/na-luzie",
      categoryId: "in:15,16,17",
      children: [
        {
          label: "Konkursy",
          url: "/konkursy",
        },
        {
          label: "Nadgodziny",
          url: "/nadgodziny",
        },
        // {
        //   label: "Quizy",
        //   url: "/quizy",
        // },
      ],
    },
    {
      label: "Forum",
      url: "https://forum.cdaction.pl/",
      openLinksInNewTab: true,
    },
  ],
  INFO: [
    {
      label: "Redakcja",
      url: "/redakcja",
    },
    {
      label: "Reklama",
      url: "mailto:reklama@cdaction.pl",
    },
    {
      label: "Prenumerata",
      url: "https://sklep.cdaction.pl/product-category/prenumerata/",
      openLinksInNewTab: true,
    },
    {
      label: "Sklep",
      url: "https://sklep.cdaction.pl/",
      openLinksInNewTab: true,
    },
    {
      label: "Kontakt",
      url: "/kontakt",
    },
  ],
  SOCIAL: [
    {
      label: "Instagram",
      url: "https://www.instagram.com/magazyncdaction/?hl=pl",
      icon: <Instagram className="w-5 h-5" />,
    },
    {
      label: "Facebook",
      url: "https://www.facebook.com/CDAction",
      icon: <Facebook className="w-5 h-5" />,
    },
    {
      label: "Twitter",
      url: "https://twitter.com/cdaction",
      icon: <Twitter className="w-5 h-5" />,
    },
    {
      label: "Discord",
      url: "https://discord.gg/GXcd5Vd8MQ",
      icon: <Discord className="w-5 h-5" />,
    },
    {
      label: "Twitch",
      url: "https://www.twitch.tv/cdaction",
      icon: <Twitch className="w-5 h-5" />,
    },
    {
      label: "YouTube",
      url: "https://www.youtube.com/channel/UCLLO-H4NQXNa_DhUv-rqN9g",
      icon: <YouTube className="w-5 h-5" />,
    },
  ],
  LEGAL: [
    {
      label: "Regulamin",
      url: "/regulamin",
    },
    {
      label: "Polityka prywatności",
      url: "/polityka-prywatnosci",
    },
    {
      label: "Regulamin konkursów",
      url: "/regulamin-konkursow",
    },
  ],
};

export const ADS: { [key: string]: any } = {
  "1631036214271-0": {
    sizes: [
      [1, 1],
      [950, 300],
      [320, 50],
      [728, 90],
      [300, 250],
      [750, 300],
      [320, 100],
      [950, 200],
      [750, 100],
      [950, 100],
      "fluid",
      [336, 280],
      [750, 200],
    ],
  },
  "1631036272651-0": {
    sizes: [
      "fluid",
      [320, 50],
      [336, 280],
      [300, 400],
      [320, 100],
      [1, 1],
      [300, 250],
      [728, 90],
      [300, 600],
    ],
  },
  "1631036367491-0": {
    sizes: [
      [750, 300],
      [750, 200],
      [728, 90],
      [750, 100],
      [300, 400],
      [336, 280],
      [320, 100],
      [950, 200],
      [300, 600],
      [950, 300],
      [950, 100],
      [300, 250],
      "fluid",
      [1, 1],
      [320, 50],
    ],
  },
  "1631036584289-0": {
    sizes: [
      "fluid",
      [320, 50],
      [336, 280],
      [300, 400],
      [320, 100],
      [1, 1],
      [300, 250],
      [300, 600],
    ],
  },
  "1631036433348-0": {
    sizes: [
      "fluid",
      [750, 300],
      [750, 100],
      [336, 280],
      [750, 200],
      [950, 200],
      [950, 100],
      [300, 600],
      [950, 300],
      [1, 1],
      [320, 100],
      [320, 50],
      [300, 400],
      [300, 250],
      [728, 90],
    ],
  },
  "1631036465772-0": {
    sizes: [
      [750, 200],
      "fluid",
      [320, 50],
      [750, 100],
      [300, 250],
      [336, 280],
      [728, 90],
      [1, 1],
      [320, 100],
      [950, 200],
      [300, 600],
      [950, 100],
      [750, 300],
      [300, 400],
      [950, 300],
    ],
  },
  "1631036643870-0": {
    sizes: [
      "fluid",
      [1, 1],
      [300, 400],
      [300, 600],
      [320, 100],
      [320, 50],
      [300, 250],
      [336, 280],
    ],
  },
  "1631036529891-0": {
    sizes: [
      [300, 600],
      [950, 300],
      [950, 200],
      "fluid",
      [300, 250],
      [320, 100],
      [300, 400],
      [750, 300],
      [320, 50],
      [750, 200],
      [336, 280],
      [728, 90],
      [1, 1],
      [950, 100],
      [750, 100],
    ],
  },
  "1631037017096-0": {
    sizes: [
      [320, 100],
      [950, 200],
      [950, 300],
      [300, 250],
      "fluid",
      [750, 200],
      [336, 280],
      [728, 90],
      [750, 300],
      [320, 50],
      [1, 1],
      [950, 100],
      [750, 100],
    ],
  },
  "1631037193481-0": {
    sizes: [
      [300, 600],
      [320, 100],
      [1, 1],
      [950, 300],
      [300, 400],
      [320, 50],
      [300, 250],
      [728, 90],
      [750, 300],
      "fluid",
      [950, 100],
      [750, 100],
      [950, 200],
      [750, 200],
      [336, 280],
    ],
  },
  "1631037220723-0": {
    sizes: [
      [300, 400],
      [320, 100],
      [950, 100],
      [950, 300],
      [750, 300],
      "fluid",
      [950, 200],
      [750, 200],
      [336, 280],
      [320, 50],
      [750, 100],
      [1, 1],
      [300, 250],
      [728, 90],
      [300, 600],
    ],
  },
  "1631037621654-0": {
    sizes: [
      "fluid",
      [750, 300],
      [750, 100],
      [336, 280],
      [750, 200],
      [950, 200],
      [950, 100],
      [950, 300],
      [1, 1],
      [320, 100],
      [320, 50],
      [300, 250],
      [728, 90],
    ],
  },
  "1631037738147-0": {
    sizes: [
      "fluid",
      [750, 300],
      [300, 400],
      [320, 50],
      [300, 600],
      [728, 90],
      [300, 250],
      [1, 1],
      [950, 300],
      [336, 280],
      [750, 100],
      [950, 100],
      [320, 100],
      [750, 200],
      [950, 200],
    ],
  },
  // Art_lewa1
  "1631037245701-0": {
    sizes: [
      [320, 50],
      [336, 280],
      [300, 400],
      [300, 250],
      [300, 600],
      [1, 1],
      [320, 100],
      "fluid",
    ],
  },
  // Art_lewa2
  "1631037279415-0": {
    sizes: [
      [320, 50],
      [336, 280],
      [320, 100],
      [300, 600],
      [300, 250],
      [1, 1],
      "fluid",
      [300, 400],
    ],
  },
  // Art_lewa3
  "1631037306768-0": {
    sizes: [
      [300, 600],
      [300, 250],
      [320, 50],
      [336, 280],
      [300, 400],
      [1, 1],
      [320, 100],
      "fluid",
    ],
  },
  // Art_lewa4
  "1631037338234-0": {
    sizes: [
      "fluid",
      [300, 600],
      [1, 1],
      [320, 100],
      [320, 50],
      [300, 400],
      [336, 280],
      [300, 250],
    ],
  },
  // Art_lewa5
  "1631037391129-0": {
    sizes: [
      "fluid",
      [1, 1],
      [300, 400],
      [300, 600],
      [320, 100],
      [320, 50],
      [300, 250],
      [336, 280],
    ],
  },
  // Art_srodtekst1
  "1631037071463-0": {
    sizes: [
      [320, 100],
      [300, 400],
      [750, 300],
      [750, 200],
      [336, 280],
      [1, 1],
      [750, 100],
      "fluid",
      [300, 600],
      [320, 50],
      [300, 250],
      [728, 90],
    ],
  },
  // Art_srodtekst2
  "1631037153011-0": {
    sizes: [
      [1, 1],
      [750, 100],
      [750, 300],
      [750, 200],
      [336, 280],
      "fluid",
      [320, 100],
      [300, 400],
      [300, 600],
      [320, 50],
      [300, 250],
      [728, 90],
    ],
  },
  // Listy_srodek1
  "1631037659219-0": {
    sizes: [
      [300, 400],
      [320, 100],
      [750, 300],
      [750, 200],
      [950, 200],
      "fluid",
      [750, 100],
      [336, 280],
      [950, 100],
      [1, 1],
      [300, 250],
      [320, 50],
      [950, 300],
      [728, 90],
      [300, 600],
    ],
  },
  // Listy_srodek2
  "1631037703348-0": {
    sizes: [
      [320, 100],
      [950, 200],
      [950, 300],
      [300, 400],
      [950, 100],
      [1, 1],
      [300, 250],
      "fluid",
      [300, 600],
      [320, 50],
      [750, 200],
      [336, 280],
      [728, 90],
      [750, 100],
      [750, 300],
    ],
  },
  // SG_sitebranding
  "1631035881684-0": {
    sizes: [
      [1, 1],
      [1920, 730],
      [1280, 420],
    ],
  },
  // Art_sitebranding
  "1631036891738-0": {
    sizes: [
      [1280, 420],
      [1, 1],
      [1920, 730],
    ],
  },
  // Listy_sitebraning
  "1631037550265-0": {
    sizes: [
      [1, 1],
      [1280, 420],
      [1920, 730],
    ],
  },
  // SG_outofpage
  "1631036037188-0": {},
  // Art_outofpage
  "1631036986209-0": {},
  // Listy_outofpage
  "1631037580557-0": {},
  // Listy_lewa1
  "1631037772625-0": {
    sizes: [
      [300, 400],
      [336, 280],
      "fluid",
      [320, 50],
      [300, 250],
      [300, 600],
      [1, 1],
      [320, 100],
    ],
  },
  // Listy_lewa2
  "1631037797490-0": {
    sizes: [
      [300, 600],
      [320, 50],
      [300, 400],
      [320, 100],
      [1, 1],
      [300, 250],
      "fluid",
      [336, 280],
    ],
  },
  // Listy_lewa3
  "1631037821839-0": {
    sizes: [
      [320, 100],
      [336, 280],
      [320, 50],
      [300, 600],
      [1, 1],
      [300, 250],
      "fluid",
      [300, 400],
    ],
  },
};
