import React, { InputHTMLAttributes, ReactNode } from "react";
import { composeClassName } from "utils/misc";

export enum InputVariant {
  Default = "default",
  Large = "large",
  Pill = "pill",
}

export type InputProps = {
  variant?: InputVariant;
  label?: string;
  isLabelUpperCase?: boolean;
  name?: string;
  type?: string;
  wrapperClassName?: string;
  endEnhancer?: ReactNode;
  error?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export default function Input({
  variant = InputVariant.Default,
  label,
  name,
  type,
  isLabelUpperCase = false,
  wrapperClassName,
  endEnhancer,
  error,
  ...props
}: InputProps): React.ReactElement {
  return (
    <div
      className={composeClassName(
        "flex flex-col relative",
        variant === InputVariant.Default && "mt-4",
        wrapperClassName
      )}
    >
      {label && (
        <label
          htmlFor={name}
          className={composeClassName(
            "text-xs text-gray-500 font-bold font-mono",
            isLabelUpperCase && "uppercase"
          )}
        >
          {label}
        </label>
      )}

      <input
        type={type}
        name={name}
        {...props}
        className={composeClassName(
          "focus:outline-none",
          variant === InputVariant.Default &&
            "h-14 text-black dark:text-white placeholder-gray-500 mt-3 px-4 py-3.5 bg-gray-100 border-2 border-gray-100 dark:border-black-100 rounded-lg text-lg focus:border-red focus:bg-white dark:bg-black-100 dark:focus:border-orange font-sans",
          variant === InputVariant.Large &&
            "text-4xl lg:text-5xl uppercase font-bold border-b border-gray-500 dark:border-gray-100 py-5 text-center border-opacity-20 dark:border-opacity-20 bg-transparent focus:border-red dark:focus:border-orange",
          variant === InputVariant.Pill &&
            "text-xs uppercase font-mono px-4 py-5 rounded-full bg-white dark:bg-black-100 font-medium",
          !!endEnhancer && "md:pr-16"
        )}
      />

      {endEnhancer && (
        <div className="absolute right-0 hidden transform -translate-y-1/2 top-1/2 md:block">
          {endEnhancer}
        </div>
      )}
      {error && <div className="mt-1/2 text-xs text-red-200">{error}</div>}
    </div>
  );
}
