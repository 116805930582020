import React, { useContext, useState } from "react";

type IProfileModalContext = {
  isEditProfileFormOpen: boolean;
  openEditProfileForm: () => void;
  closeEditProfileForm: () => void;
};

const ProfileModalContext = React.createContext({} as IProfileModalContext);

export function ProfileModalProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [isEditProfileFormOpen, setIsEditProfileFormOpen] = useState(false);

  const closeEditProfileForm = () => setIsEditProfileFormOpen(false);

  const openEditProfileForm = () => setIsEditProfileFormOpen(true);

  return (
    <ProfileModalContext.Provider
      value={{
        isEditProfileFormOpen,
        closeEditProfileForm,
        openEditProfileForm,
      }}
    >
      {children}
    </ProfileModalContext.Provider>
  );
}

export const useProfileModalContext = (): IProfileModalContext =>
  useContext(ProfileModalContext);
