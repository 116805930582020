export enum DefaultSorter {
  Newest = "-publishedAt",
  Oldest = "publishedAt",
}

export enum CommentsSorter {
  Newest = "-createdAt,-createdAt",
  Oldest = "createdAt,-createdAt",
  Best = "-upvoteCount,-createdAt",
}

export enum GamesSorter {
  Newest = "-createdAt",
  Best = "-usersRating",
  Alphabetical = "originalTitle",
}
