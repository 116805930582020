import Button from "components/Button";
import { Eye } from "components/Icons";
import type { ReactElement } from "react";

type ExitPreviewPromptProps = {
  previewData?: { [key: string]: string };
};

export default function ExitPreviewPrompt({
  previewData,
}: ExitPreviewPromptProps): ReactElement {
  return (
    <div className="fixed bottom-0 left-0 right-0 z-20 flex items-center p-5 text-white bg-red">
      <Eye className="flex-shrink-0" />
      <span className="mx-3 font-mono text-xs font-medium uppercase">
        Uwaga! Jesteś w trybie podglądu treści: <u>{previewData?.title}</u>
      </span>

      <Button
        href="/api/exit-preview"
        $as="a"
        className="ml-auto text-white border-white"
      >
        Opuść
      </Button>
    </div>
  );
}
