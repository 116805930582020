import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import { ReactElement, useState } from "react";
import { NavItem } from "utils/constants";

import { ChevronDown } from "../Icons";

type FooterNavigationProps = {
  title: string;
  items?: NavItem[];
  openLinksInNewTab?: boolean;
};

export default function FooterNavigation({
  title,
  items,
  openLinksInNewTab,
}: FooterNavigationProps): ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);

  const router = useRouter();

  return (
    <div className="w-full pb-1 mb-4 border-b-2 lg:w-auto border-b-gray-100 dark:border-b-black-100 lg:border-b-0">
      <div
        className="flex items-center justify-between text-2xl font-bold text-gray-500 uppercase font-display"
        onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
      >
        {title}

        <ChevronDown
          className={`block lg:hidden ${isExpanded && `rotate-180`}`}
        />
      </div>
      <ul
        className={`flex-col flex-wrap mt-2 h-auto xl:h-56 lg:flex ${
          isExpanded ? "flex" : "hidden"
        }`}
      >
        {items &&
          Object.values(items).map((item, index) => (
            <li
              key={`footerNavigationItem${index}`}
              className={`whitespace-nowrap font-mono text-xs font-medium leading-looser tracking-wider uppercase mr-8 cursor-pointer hover:text-red dark:hover:text-orange ${
                `/${router.asPath.split("/")[1]}` === item.url
                  ? "text-red dark:text-orange"
                  : "text-gray-500"
              }`}
            >
              <Link href={item.url} passHref>
                <a
                  className="flex items-center"
                  target={
                    openLinksInNewTab || item.openLinksInNewTab
                      ? "_blank"
                      : "_self"
                  }
                  rel={
                    openLinksInNewTab || item.openLinksInNewTab
                      ? "noreferrer noopener"
                      : ""
                  }
                >
                  {item.icon && (
                    <span className="flex items-start justify-center w-5 h-5 mr-3">
                      {item.icon}
                    </span>
                  )}

                  <span>{item.label}</span>
                </a>
              </Link>
            </li>
          ))}

        {title === "Menu" && (
          <li className="whitespace-nowrap font-mono text-xs font-medium leading-looser tracking-wider uppercase mr-8 cursor-pointer hover:text-red dark:hover:text-orange text-gray-500">
            <Link href={"https://theprotocol.it/"} passHref>
              <a
                className="flex items-center"
                target={"_blank"}
                rel={"dofollow noopener"}
              >
                <span>Praca w IT</span>
              </a>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
}
